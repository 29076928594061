import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import Toaster from '@meforma/vue-toaster';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import "./assets/css/customStyle.css";
import vuePaginate from 'vue-paginate';
import VueApexCharts from "vue3-apexcharts";
import axios from 'axios';
import VueAxios from 'vue-axios';



//axios.defaults.baseURL = "https://localhost:44330/"; //LOCALHOST
//axios.defaults.baseURL = "https://api.wariservice.com.pe/"; //PRODUCCION

var token = localStorage.getItem("SessionToken");
if(token)
   axios.defaults.headers.common['Authorization'] = ('Bearer ' + JSON.parse(token));


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Toaster);
appInstance.use(VueSweetalert2);
appInstance.component("v-select", vSelect);
appInstance.component("paginate", vuePaginate);
appInstance.use(VueApexCharts);
appInstance.use(VueAxios, axios);

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    // either use window.config
    window.config = config
    axios.defaults.baseURL = config.KEY;
    // or use [Vue Global Config][1]
    appInstance.config.globalProperties.config = config
    // FINALLY, mount the app
    appInstance.mount("#app");
  })
  
// appInstance.mount("#app");
// createApp(App).mount('#app')
