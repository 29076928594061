<template>
  <div class="p-0" v-show="vshow">
   <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="geBusqueda()" class="btn btn-secondary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Descargar Plantilla
      </button>
      <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Masivo Xml
      </button>
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-5 col-sm-5 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
            >
            </v-select>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPLEADO</label>
          <div class="">
            <input
              v-model="filtro.Empleado"
              name="Empleado"
              class="form-control input-sm"
              placeholder=""
              ref="refEmpleado"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
          <label class="control-label label-sm">ESTADO</label>
          <div class="">
            <v-select
              v-model="vselect.Estado"
              name="Estado"
              :clearable="true"
              class="v-select-form"
              label="label"
              placeholder="--Seleccione estado--"
              :options="list.Estado"
              ref="refEstado"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="
            col-md-12 col-sm-12 col-xs-12
            p-l-0 p-r-0
            m-b-10
            paginate-padding
          "
          style="overflow-x: auto"
        >
          <table
            class="
              table table-responsive table-hover table-bordered
              custom-table
            "
          >
            <thead>
              <tr>
                <th>EMPRESA</th>
                <th>DOCUMENTOS</th>
                <th>NOMBRES Y APELLIDOS</th>
                <th>CLASE - CATEGORIA</th>
                <th>EMISIÓN</th>
                <th>REVALIDACIÓN</th>
                <th>ACTIVO</th>
                <th>ESTADO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.Empresa }}</td>
                <td class="text-center">{{ item.DocIdentidad }}</td>
                <td class="text-left">{{ item.Nombres }}, {{ item.Paterno }}{{ item.Materno }}</td>
                <td class="text-left">{{ item.ClaseCategoria }}</td>
                <td class="text-left">{{ item.FechaEmision }}</td>
                <td class="text-left">{{ item.FechaRevalidacion }}</td>
                <td class="text-left">
                  <template v-if="item.Activo  == true">
                    ACTIVO
                  </template>
                  <template v-else>
                    INACTIVO
                  </template>
                </td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.estado === 'VIGENTE'">
                    <div
                      class="label label-default"
                      style=" color: white; background-color: #009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'VENCIDO'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: #e02e2e;
                        padding: 3px 10px;
                        border-radius: 5px;"
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'POR VENCER'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: rgb(246 137 54);
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'NO REGISTRADO'">
                    <div
                      class="label label-default"
                      style="
                        color: white;
                        background-color: #8d8d8d;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                </td>
                <td class="text-center" style="text-align: center">
                  <template v-if="item.LicenciaConduccionId > 0">
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span
                        class="fa fa-pencil"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)"
                    >
                      <span
                        class="fa fa-trash-o"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                  </template>
                  <template v-else>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-success m-r-10"
                      id="Info"
                      style="color: #178719"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span class="fa fa-gear" style="font-size: 1.6em"></span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Licencia de Conducir
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Empresa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Empresa"
                  name="Empresa"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refEmpresa"
                  autocomplete="off"
                  disabled/>
              </div>
            </div>
          </div>

          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Documento</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Documento"
                  name="Documento"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refFechaContrato, 'input', 100)"
                  ref="refDocumento"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Nombres</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Nombres"
                  name="Nombres"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refFechaContrato, 'input', 100)"
                  ref="refNombres"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Clase-Categoria</label>
              <div class="">
                <input
                  v-model="filtro.Popup.ClaseCategoria"
                  name="ClaseCategoria"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refNumLicencia, 'input', 100)"
                  ref="refClaseCategoria"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">N° Licencia</label>
              <div class="">
                <input
                  v-model="filtro.Popup.NumLicencia"
                  name="NumLicencia"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refEmision, 'input', 100)"
                  ref="refNumLicencia"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Emision</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Emision"
                  name="Emision"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refRevalidacion, 'input', 100)"
                  ref="refEmision"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Revalidación</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Revalidacion"
                  name="Revalidacion"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.btnGrabar2, 'btn', 100)"
                  ref="refRevalidacion"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Días Faltante</label>
              <div class="">
                <input
                  v-model="filtro.Popup.DiaFaltante"
                  name="DiaFaltante"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDiaFaltante"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Estado</label>
              <div class="">
                <template v-if="filtro.Popup.Estado === 'VIGENTE'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #009900;
                      padding: 3px 10px;  
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'VENCIDO'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #e02e2e;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'POR VENCER'">
                  <div
                    class="label label-default"
                    style="
                      text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: rgb(246 137 54);
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-10 col-sm-10 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Observación:</label>
              <div class="">
                <textarea
                  v-model="filtro.Popup.Observacion"
                  name="Observacion"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refObservacion"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
              <label class="control-label label-sm">&nbsp;</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Activo"
                  name="Activo"
                  id="IdActivo"
                  type="checkbox"
                  ref="refActivo"
                  style="margin-right: 5px;"
                />
                <label for="IdActivo" class="control-label label-sm">Activo</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModal2" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="register()" type="button" class="btn btn-primary" ref="btnGrabar2">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="p-0" v-show="!vshow">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
     <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
     </button>
    </div>
    <div class="White-Box">
      <appVueAddExcelLicencia></appVueAddExcelLicencia>
     </div>
  </div>
</div>
</template>

<script>
import { APP } from "../../Content/base";
import appVueAddExcelLicencia from "../Licencia/AddExcelLicencia.vue"
    export default {
        name:"LicenciaConducir",
        el: "#LicenciaConducir",
        data() {
            return {
                filtro: {
                    Empleado: "",
                    Popup: {
                      LicenciaConduccionId:0,
                      EmpresaId:0,
                      Empresa:'',
                      Documento:'',
                      Nombres:'',

                      TipoDocIdentidad:'',
                      ClaseCategoria:'',
                      NumLicencia:'',
                      Emision:'',
                      Revalidacion:'',
                      DiaFaltante: "",
                      Estado: "",
                      Observacion:'',
                      Activo: true,
                    },
                },
                list: {
                    Empresa: [],
                    Estado: [
                    { id: 0, label: "NO REGISTRADO" },
                    { id: 1, label: "VIGENTE" },
                    { id: 2, label: "VENCIDO" },
                    { id: 3, label: "POR VENCER" },
                    ],
                    Popup: {
                    },
                    TablaDetalle: [],
                },
                vselect: {
                    Empresa: null,
                    Estado: null,
                    Popup: {
                    },
                },
                
                NUM_RESULTS: 10, // Numero de resultados por página
                pag: 1, // Página inicial
                vshow: true,
            };
        },
        async mounted() {
            let _this = this;

            await _this.getEmpresa();
            //await _this.getAseguradora();
        },
        components: {appVueAddExcelLicencia},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
            getEmpresa: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/ListEmpresa")
                .then((res) => {
                if (res.data.EsCorrecto) {
                    _this.list.Empresa = res.data.Valor;
                    _this.vselect.Empresa = _this.list.Empresa[0];
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            geBusqueda: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            var request = {
                IdEmpresa: _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 1,
                Empleado: _this.filtro.Empleado || "",
                estado: _this.vselect.Estado ? parseInt(_this.vselect.Estado.id) : -1,
            };

            await this.axios
                .post("api/licenciaConducir/ListSeguroSoatXVehiculo", request)
                .then((res) => {
                    if (res.data.EsCorrecto) {
                        _this.list.TablaDetalle = res.data.Valor;
                        if( _this.list.TablaDetalle.length >0){
                            _this.pag = 1
                          }
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                })
                .catch((error) => {
                      APP.msg.error(
                          this.$toast,
                          "Token expirado Inicie session..!!" + error
                      );
                      localStorage.clear();
                      this.$store.state.isLogeado = false;
                      this.$router.push("/");
                });
            },
            getFormula: async function (_Fecha) {
            let _this = this;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/revisionTecnica/getFormula/" + _Fecha)
                .then((res) => {
                if (res.data.EsCorrecto) {
                    var _Data = res.data.Valor;
                    if (_Data) {
                    _this.filtro.Popup.DiaFaltante = _Data.diasFaltantes;
                    _this.filtro.Popup.Estado = _Data.estado;
                    }
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            openModal: async function (_item) {
            let _this = this;
            setTimeout(async () => {
                _this.cleanField();
                _this.filtro.Popup.LicenciaConduccionId = _item.LicenciaConduccionId;

                if(_item.LicenciaConduccionId > 0){

                      if(_item.FechaEmision){
                          var sp =   _item.FechaEmision.split('/');
                          var newValue = sp[2] + '-' + sp[1]+ '-' + sp[0];
                          _this.filtro.Popup.Emision = newValue; 
                      }

                      if(_item.FechaRevalidacion){
                          var sp2 =   _item.FechaRevalidacion.split('/');
                          var newValue2 = sp2[2] + '-' + sp2[1]+ '-' + sp2[0];
                          _this.filtro.Popup.Revalidacion = newValue2; 
                      }

                      _this.filtro.Popup.ClaseCategoria= _item.ClaseCategoria;
                      _this.filtro.Popup.NumLicencia= _item.NroLicencia;
                      _this.filtro.Popup.Estado= _item.estado;
                      _this.filtro.Popup.Observacion= _item.Obs;
                      _this.filtro.Popup.Activo= _item.Activo;
                    
                }
                _this.filtro.Popup.EmpresaId = _item.EmpresaId;
                _this.filtro.Popup.Empresa= _item.Empresa;
                _this.filtro.Popup.Nombres= _item.Nombres + ', ' +_item.Paterno + ' ' + _item.Materno ;
                _this.filtro.Popup.Documento= _item.DocIdentidad;
                _this.filtro.Popup.TipoDocIdentidad= _item.TipoDocIdentidad;

                this.nextFocus(this.$refs.refClaseCategoria, 'input', 200);
            }, 300);
            },
            cleanField: function(){
                let _this = this;

                      _this.filtro.Popup.LicenciaConduccionId= 0;
                      _this.filtro.Popup.EmpresaId= 0;
                      _this.filtro.Popup.Empresa= '';
                      _this.filtro.Popup.Documento= '';
                      _this.filtro.Popup.Nombres= '';
                      _this.filtro.Popup.TipoDocIdentidad= '';
                      _this.filtro.Popup.ClaseCategoria= '';
                      _this.filtro.Popup.NumLicencia= '';
                      _this.filtro.Popup.Emision= '';
                      _this.filtro.Popup.Revalidacion= '';
                      _this.filtro.Popup.DiaFaltante= '';
                      _this.filtro.Popup.Estado= '';
                      _this.filtro.Popup.Observacion= '';
                      _this.filtro.Popup.Activo= true;

            },
            register: async function () {
            let _this = this;
               var validando = await _this.validarCampos();
               if(validando){
                      this.axios.defaults.headers.common["Authorization"] =
                          "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                      var usuario = JSON.parse(localStorage.getItem("Usuario"));

                      var request = {
                        "LicenciaConduccionId":  _this.filtro.Popup.LicenciaConduccionId > 0 ? parseInt( _this.filtro.Popup.LicenciaConduccionId) : 0,
                        "EmpresaId": _this.filtro.Popup.EmpresaId,
                        "TipoDocIdentidad": _this.filtro.Popup.TipoDocIdentidad,
                        "DocIdentidad":  _this.filtro.Popup.Documento,
                        "NroLicencia": _this.filtro.Popup.NumLicencia,
                        "ClaseCategoria": _this.filtro.Popup.ClaseCategoria,
                        "FechaEmision": _this.filtro.Popup.Emision,
                        "FechaRevalidacion": _this.filtro.Popup.Revalidacion,
                        "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
                        "Activo": _this.filtro.Popup.Activo,
                        "Obs": _this.filtro.Popup.Observacion,
                        "Tipo":  _this.filtro.Popup.LicenciaConduccionId > 0 ? '2' : '1'
                      };

                      await this.axios
                          .post("api/licenciaConducir/registerUpdate", request)
                          .then(async (res) => {
                          if (res.data.EsCorrecto) {
                          
                              APP.msg.success(this.$toast, "Se registro correctamente ..!");
                              document.getElementById('closeModal2').click();
                              _this.cleanField();
                              await _this.geBusqueda();
                          } else {
                              APP.msg.error(this.$toast, res.data.Mensaje);
                          }
                          })
                          .catch((error) => {
                              APP.msg.error(
                                  this.$toast,
                                  "Token expirado Inicie session..!!" + error
                              );
                              localStorage.clear();
                              this.$store.state.isLogeado = false;
                              this.$router.push("/");
                          });
                  }
            },
            Delete: async function (_item) {
            
                if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

                this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/licenciaConducir/delete/" + (_item.LicenciaConduccionId > 0 ? String(_item.LicenciaConduccionId) : ''))
                    .then(async (res) => {
                    if (res.data.EsCorrecto) {
                        APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                        await this.geBusqueda();
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                    .catch((error) => {
                        APP.msg.error(
                            this.$toast,
                            "Token expirado Inicie session..!!" + error
                        );
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });

            }

            },
            validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.ClaseCategoria === ''){
                  APP.msg.warning( this.$toast,"Ingrese Clase Categoria");
                  _this.nextFocus(this.$refs.refClaseCategoria, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.NumLicencia === ''){
                  APP.msg.warning( this.$toast,"Ingrese Numero Licencia");
                  _this.nextFocus(this.$refs.refNumLicencia, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Emision === ''){
                  APP.msg.warning( this.$toast,"Ingrese Fecha Emisión");
                  _this.nextFocus(this.$refs.refEmision, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Revalidacion === ''){
                  APP.msg.warning( this.$toast,"Ingrese Fecha Revalidación");
                  _this.nextFocus(this.$refs.refRevalidacion, 'input', 200);
                  response = false;
                  return response;
                }
                return response;
            },  
            VerImporteMasivo: async function(){
              document.getElementById('LimpiarDataLicencia').click();
              this.vshow = !this.vshow;
              if(this.vshow){
                await this.geBusqueda();
              }
            }
        },
        watch: {
            "vselect.Empresa": async function (value, oldValue) {
              let _this = this;
              if (value != null && value != oldValue) {
                  await _this.geBusqueda();
              }
            },
            "vselect.Estado": async function () {
              let _this = this;
                await _this.geBusqueda();
            },
            "filtro.Empleado": async function(){

              if (!(await APP.Func.delayWhileIsSearching())) return;
               await this.geBusqueda();
            },
            "filtro.Popup.Revalidacion": async function (value) {
            let _this = this;
            if (value != null && value.length == 10) {
                await _this.getFormula(value);
            }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>