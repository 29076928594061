<template>
  <div class="layoutBotoon">
    <footer
      class=" d-flex flex-wrap align-items-center border-top" style="justify-content: space-around !important;">
      <p class="col-md-2 mb-0 text-muted" style="font-weight: 500;">&copy; 2023 Jelaf Integradores</p>
      <ul class="nav col-md-4 justify-content-center">
        <li class="nav-item">
          <a href="#" class="nav-link px-2 text-muted" style="font-weight: 500;"><span style="color:blue;"><i class="fa fa-facebook"></i></span>&nbsp;Facebook</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link px-2 text-muted" style="font-weight: 500;"><span style="color:green;"><i class="fa fa-whatsapp"></i></span>&nbsp;WhatsApp</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link px-2 text-muted" style="font-weight: 500;"><span style="color:skyblue;"><i class="fa fa-twitter"></i></span>&nbsp;Twitter</a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
export default {
    name: "LayoutFooter",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
.layoutBotoon {
    /* bottom: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
    padding: 0px !important; */
    bottom: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
    padding: 0px !important;
    margin: 0px !important;
}

</style>