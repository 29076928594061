<template>
    <div class="Container">
     <div style="margin-bottom: 5px !important">
       <button class="btn btn-primary" style="margin-right: 3px !important">
         <span style="margin-right: 5px"
           ><i class="fa fa-align-justify"></i
         ></span>
         Agregar
       </button>
       <button class="btn btn-success" style="margin-right: 3px !important">
         <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
         >Exportar Excel
       </button>
       <button class="btn btn-secondary">
         <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
         >Refrescar
       </button>
     </div>
     <div class="White-Box">
       <div class="row">
         <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
           <label class="control-label label-sm">Clase Brevete</label>
           <div class="">
             <input
               v-model="filtro.ClaseBreveteInp"
               name="producto"
               class="form-control input-sm"
               placeholder="Ingrese Clase Brevete"
               ref="refClaseBreveteInp"
               autocomplete="off"
             />
           </div>
         </div>
         <div class="row col-md-2 col-sm-2 col-xs-12 separate-in-form">
           <label class="col-md-12" style="margin-bottom: 5px">&nbsp;</label>
           <label for="checkEstado" class="col-md-6 control-label label-sm"
             >Estado</label
           >
           <div class="col-md-6" style="line-height: 39px">
             <input
               type="checkbox"
               id="checkEstado"
               v-model="filtro.Estado"
               ref="refClaseBreveteInp"
             />
           </div>
         </div>
       </div>
       <div class="row">
         <div
           class="
             col-md-12 col-sm-12 col-xs-12
             p-l-0 p-r-0
             m-b-10
             paginate-padding
           "
           style="overflow-x: auto"
         >
           <table
             class="
               table table-responsive table-hover table-bordered
               custom-table
             "
           >
             <thead>
               <tr>
                 <th>Clase Brevete</th>
                 <th>Usuario</th>
                 <th>Fecha Registro</th>
                 <th>Hora Registro</th>
                 <th>Estado</th>
                 <th>Acciones</th>
               </tr>
             </thead>
             <tbody>
                 <tr v-for="(item, key) in list.TablaDetalle"  :key="key+1">
                   <td class="text-center">{{ item.id }}</td>
                   <td class="text-center">{{ item.label }}</td>
                   <td class="text-left">{{ item.label }}</td>
                   <td class="text-left">{{ item.label }}</td>
                   <td class="text-left">{{ item.label }}</td>
                   <td class="text-center">
                   </td>
                 </tr>
             </tbody>
           </table>
         </div>
         <!-- <div class="col-md-12" style="text-align: right">
           <paginate-links
             v-show="list.TablaDetalle.length > 0"
             for="items"
             :limit="3"
             :async="true"
             :classes="{ ul: 'pagination' }"
             :show-step-links="true"
           >
           </paginate-links>
         </div> -->
       </div>
     </div>
   </div>
 
 </template>
 
 <script>
 export default {
   name: "ClaseBrevete",
   el: '#ClaseBrevete',
   data() {
     return {
       filtro: {
         ClaseBreveteInp: "",
       },
       list: {
         TablaDetalle: [
         ],
       },
       vselect: {},
       paginate: ["items"],
       per: 10,
     };
   },
 };
 </script>
 
 <style  scoped>
 </style>