<template>
  <a  href="javascript:void(0)"
       class="text-primary m-r-10"
       id="InfoOpenModalIndicador"
       data-bs-toggle="modal"
       data-bs-target="#exampleModal5"
       style="display: none;"
       >
       <span class="fa fa-pencil" style="font-size: 1.6em"></span>
       </a>
    <div class="form-div" style="margin-bottom: 10px">
      <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length == 0">
          <div class="drop-area"  id="dropAreaDrag">
              <i style="margin-top: 30px; color: #1fa71f !important;" class="fa fa-file-excel-o fa-4x"></i>
              <h2>Suelta aqui el archivo o seleccionalo</h2>
              <button @click="$refs.refInputLogo1.click()">Seleccionar archivo</button>
              <input type="file" @change="readExcel($event)" name="" id="input-file" ref="refInputLogo1" accept=".xlsx, .xls, .xlsm" hidden multiple style="visibility: hidden;" />
          </div>
          <div id="preview"></div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length > 0">
        <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
        <table class=" table table-responsive table-hover table-bordered custom-table">
          <thead>
            <tr>
              <th>PLACA</th>
              <th>INDICADOR</th>
              <th>FECHA</th>
              <th>OBSERVACIÓN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in list.TablaDetalle" :key="key + 1"
              v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key " >
              <td class="text-left">{{ item.PLACA }}</td>
              <td class="text-left">{{ item.Indicador }}</td>
              <td class="text-left">{{ item.Fecha }}</td>
              <td class="text-left">{{ item.Observacion }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important">
        <nav aria-label="Page navigation" class="text-center">
          <ul class="pagination text-center pull-right">
            <li>
              <a  href="#" aria-label="Previous" v-show="pag != 1"  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px">
                <span aria-hidden="true">
                  <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                    <i class="fa fa-angle-double-left"></i>
                  </button>
                </span>
              </a>
            </li>
            <li>
              <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px">
                <span aria-hidden="true">
                  <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                    <i class="fa fa-angle-double-right"></i>
                  </button>
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important; text-align: right;">
        <button @click="limpiarData()"   class="btn btn-danger" style="margin-right: 3px !important" id="IdLimpDtaIndicador">
             <span style="margin-right: 5px"><i class="fa fa-close"></i></span>Limpiar Data
        </button>
        <button @click="register()"   class="btn btn-info" style="margin-right: 3px !important">
             <span style="margin-right: 5px"><i class="fa fa-save"></i></span>Grabar Data
        </button>
      </div>
      </div>
    </div>

    <div
    class="modal fade"
    id="exampleModal5"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel5"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel5">
            Observaciones Encontradas
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table">
            <thead>
              <tr>
                <th>FILA</th>
                <th>OBSERVACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in list.popUp.table" :key="key + 1">
                <td class="text-left">{{item.fila}}</td>
                <td class="text-left">{{item.errorMessage}}</td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="close" type="button" class="btn btn-danger" data-bs-dismiss="modal" id="closeModalPrueba">
            <i class="fa fa-close" style="margin-right: 5px"></i>Cerrar
          </button>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import * as XLSX from 'xlsx'
import moment from "moment";
import { APP } from "../../Content/base";

export default {
    name:"AddExcelIndi",
    el: "#AddExcelIndi",
    data() {
    return {
         filtro: {},
         list: {
           TablaDetalle:[],
           popUp:{
            table:[]
           }
         },
         vselect: {},

         NUM_RESULTS: 10, // Numero de resultados por página
         pag: 1, // Página inicial
      };
     },
 async mounted() {
    let _this = this;
    document.addEventListener("dragover", function (event) {
     event.preventDefault();
     document.getElementById('dropAreaDrag').classList.add('active');
    });

    document.addEventListener("dragleave", function (event) {
      if (event.target.className == "dropAreaDrag") {
       event.target.style.border = "";
      }
    });

    document.addEventListener("drop", async function (event) {
       event.preventDefault();
       await _this.readExcel(event, 1);
       document.getElementById('dropAreaDrag').classList.remove('active');
  
    });
    document.addEventListener("dropAreaDrag", function (event) {
       if (event.target.className == "droptarget") {
         event.target.style.border = "3px dotted red";
       }
    });
  },
  methods: {
    readExcel: async function (e,t) {
      var file = null;
         if(t == 1)
          file = e.dataTransfer.files[0];
         else
          file = e.target.files[0];
          //const file = e.target.files[0];
          const promise = new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = async (e) => {
                 const bufferArray = e.target.result;
                 const wb = XLSX.read(bufferArray, { type: "buffer" });
                 const wsname = wb.SheetNames[0];
                 const ws = wb.Sheets[wsname];
                 const data = XLSX.utils.sheet_to_json(ws);   
                
                // console.log(data);
                 await this.procesarDta(data);

                 document.getElementById('dropAreaDrag').classList.remove('active');
                 document.getElementById("input-file").value = "";
                
              };
              fileReader.onerror = (error) => {
                  reject(error);
              };
          });
          promise.then((d) => {
              alert(d);
          });
    },
    procesarDta: async function(_data){
    let _this = this;

    for (let i = 0; i < _data.length; i++) {
      _data[i].Fecha = await _this.ExcelDateToJSDate(_data[i].Fecha);
    }

    this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
      var usuario = JSON.parse(localStorage.getItem("Usuario"));

    var request = {
      "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
    };
    
    request.Detalle = [];
    _data.forEach((_item, key) => {
            request.Detalle.push({
              "Item": key+1,
              "PLACA":String(_item.PLACA),
              "Indicador": String(_item.Indicador),
              "Fecha": String(_item.Fecha),
              "Observacion": String(_item.Observacion),
              "IdIndicador": 0,
              "EmpresaId": 0,
              "VehiculoId": ""
            });
      });
    
    await this.axios
      .post("api/indicadores/IndicadorImportMasivoValidate", request)
      .then(async (res) => {
        if (res.data.EsCorrecto) {
        
          if(res.data.Valor.length>0){
             _this.list.popUp.table = res.data.Valor;
             document.getElementById('InfoOpenModalIndicador').click();
          }else{
            _this.list.TablaDetalle = _data;
          }
    
    
        } else {
          APP.msg.error(this.$toast, res.data.Mensaje);
        }
      })
      .catch((error) => {
        APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
        localStorage.clear();
        this.$store.state.isLogeado = false;
        this.$router.push("/");
      }); 
    },
    ExcelDateToJSDate: async function(date) {
     var newdate = new Date(Math.round((date - 25569)*86400*1000));
     var ss =  moment(newdate).add(1, 'days');
     return moment(ss,"YYYY-MM-DD").format('DD/MM/YYYY');
    },
    limpiarData: function(){
    let _this = this;
    _this.list.TablaDetalle = [];
    },
    register: async function () {
      let _this = this;

          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
          };

          request.Detalle = [];
             _this.list.TablaDetalle.forEach((_item,key) => {
                  request.Detalle.push({
                    "Item": key+1,
                    "PLACA":String(_item.PLACA),
                    "Indicador": String(_item.Indicador),
                    "Fecha": String(_item.Fecha),
                    "Observacion": String(_item.Observacion),
                    "IdIndicador": 0,
                    "EmpresaId": 0,
                    "VehiculoId": ""
                  });
            });

          await this.axios
            .post("api/indicadores/InsertMasivoIndicador", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                 if(res.data.Estado)
                 {
                  APP.msg.success(this.$toast, res.data.Mensaje);
                  _this.list.TablaDetalle = [];
                 }
                 else
                 {
                  APP.msg.warning(this.$toast,  res.data.Mensaje);
                 }
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      
    },
    close: function(){
      let _this = this;
      _this.list.popUp.table = [];
    },


  },
  }
</script>

<style scoped>

</style>