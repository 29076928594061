<template>
  <div style="padding-bottom: 50px !important;">
    <!-- <h3>soy dashboard</h3> -->
    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
      <div class="slideshow-container1">
        <!-- Full-width images with number and caption text -->
        <div class="mySlides">
          <div class="numbertext">1 / 3</div>
          <img
            src="../assets/img/fondo2.jpg"
            style="width: 100%; height: 350px"
          />
          <div class="text">Caption Text</div>
        </div>

        <div class="mySlides">
          <div class="numbertext">2 / 3</div>
          <img
            src="../assets/img/fondo1.jpg"
            style="width: 100%; height: 350px"
          />
          <div class="text">Caption Two</div>
        </div>

        <div class="mySlides">
          <div class="numbertext">3 / 3</div>
          <img
            src="../assets/img/fondo3.jpg"
            style="width: 100%; height: 350px"
          />
          <div class="text">Caption Three</div>
        </div>

        <!-- Next and previous buttons -->
        <a class="prev" @click="plusSlides(-1)">&#10094;</a>
        <a class="next" @click="plusSlides(1)">&#10095;</a>
      </div>

      <!-- The dots/circles -->
      <div
        class="form-div col-md-12 col-sm-12 col-xs-12 p-0"
        style="text-align: center; margin-left: 48%"
      >
        <span class="dot" @click="currentSlide(1)"></span>
        <span class="dot" @click="currentSlide(2)"></span>
        <span class="dot" @click="currentSlide(3)"></span>
      </div>
    </div>
    <!-- <button @click="getDataPrueba()">prueba</button> -->
    <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
      <!-- <div class="col-md-6 col-sm-6 col-xs-12 p-0">
        <apexchart
          width="500"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 p-0">
        <apexchart
          width="500"
          type="line"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div> -->
    </div>
  </div>
</template>

<script>
import { APP } from "../Content/base";
//import axios from "axios";

export default {
  name: "Dashboard",
  data: function () {
    return {
      list: {
        Tabla2: [],
      },
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
        colors: ["#4FA6EF"],
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
      slideIndex: 1,
    };
  },
  mounted() {

    this.showSlides(this.slideIndex);
    this.getEmpresa();

    var usuario = JSON.parse(localStorage.getItem("Usuario"));
    if(usuario && usuario.Usuario != '')
       this.$store.state.Usuario = usuario.Usuario;
  },
  methods: {
    openModal: function () {
      this.$refs.AddRevisionTecn.ShowModal();
    },
    plusSlides: function (n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide: function (n) {
      this.showSlides((this.slideIndex = n));
    },
    showSlides: function (n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active1", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active1";
    },
    getDataPrueba: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios.get("api/netcore/Vehiculo_Autocomplete/A")
      .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Tabla2 = res.data.Valor;
            APP.msg.info(this.$toast, "Se encontro correctamente ..!");
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch(async (error) => {
          APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
          if(await APP.msg.refuse(this.$swal.fire, '','Token expirado Inicie session..!!' )){
            //localStorage.clear();
            //this.$store.state.isLogeado = false;
            this.$router.push("/");
          }
        });
    },
    getEmpresa: async function () {
      //let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            this.$store.state.ListaEmpresa = res.data.Valor;
            
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
  },
};
</script>

<style  scoped>
.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container1 {
  position: relative;
  margin: 5px 15px !important;
  width: 100%;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active1,
.dot:hover {
  background-color: #2e3f5c;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}
</style>