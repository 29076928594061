<template>
  <div class="p-0" v-show="vshow">
    <div class="Container">
      <div style="margin-bottom: 5px !important">
      <button @click="geBusqueda()" class="btn btn-secondary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Descargar Plantilla
      </button>
      <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Masivo Xml
      </button>
    </div>
      <div class="White-Box">
        <div class="form-div" style="margin-bottom: 10px">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
            >
            </v-select>
          </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
          <label class="control-label label-sm">PLACA</label>
          <div class="">
            <input
              v-model="filtro.Placa"
              name="Placa"
              class="form-control input-sm"
              placeholder=""
              ref="refPlaca"
              autocomplete="off"
            />
          </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">ESTADO</label>
          <div class="">
            <v-select
              v-model="vselect.Estado"
              name="Estado"
              :clearable="true"
              class="v-select-form"
              label="label"
              placeholder="--Seleccione estado--"
              :options="list.Estado"
              ref="refEstado"
            >
            </v-select>
          </div>
            </div>
        </div>
        <div class="row">
          <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding " style="overflow-x: auto">
            <table class="  table table-responsive table-hover table-bordered custom-table" >
              <thead>
                <tr>
                  <th>EMPRESA</th>
                  <th>PLACA</th>
                  <th>N° Tuc</th>
                  <th>DESDE</th>
                  <th>HASTA</th>
                  <th>N° RESOLUCIÓN</th>
                  <th>COND. VEHICULO</th>
                  <th>ESTADO</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                 v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key " >
                    <td class="text-left">{{ item.Empresa }}</td>
                    <td class="text-center">{{ item.Placa }}</td>
                    <td class="text-center">{{ item.TUCNro }}</td>
                    <td class="text-left">{{ item.AutorizaDesde }}</td>
                    <td class="text-left">{{ item.AutorizaHasta }}</td>
                    <td class="text-left">{{ item.NroResolucion }}</td>
                    <td class="text-left">
                      <template v-if=" item.CondicionVehiculo === 1"> OPERATIVO</template>
                      <template v-else-if="item.CondicionVehiculo === 2">RESERVA</template>
                    </td>
                    <td style="width: 13%" class="text-center">
                      <template v-if="item.estado === 'VIGENTE'">
                        <div
                          class="label label-default"
                          style=" color: white; background-color: #009900; padding: 3px 10px; border-radius: 5px;">
                          {{ item.estado }}
                        </div>
                      </template>
                      <template v-else-if="item.estado === 'VENCIDO'">
                        <div
                          class="label label-default"
                          style="color: white; 
                            background-color: #e02e2e;
                            padding: 3px 10px;
                            border-radius: 5px;" >
                          {{ item.estado }}
                        </div>
                      </template>
                      <template v-else-if="item.estado === 'POR VENCER'">
                        <div
                          class="label label-default"
                          style="color: white;
                            background-color: rgb(246 137 54);
                            padding: 3px 10px;
                            border-radius: 5px;
                          "
                        >
                          {{ item.estado }}
                        </div>
                      </template>
                      <template v-else-if="item.estado === 'NO REGISTRADO'">
                        <div
                          class="label label-default"
                          style="
                            color: white;
                            background-color: #8d8d8d;
                            padding: 3px 10px;
                            border-radius: 5px;"
                        >
                          {{ item.estado }}
                        </div>
                      </template>
                    </td>
                    <td class="text-center" style="text-align: center">
                      <template v-if="item.TUCId > 0">
                        <a
                          href="javascript:void(0)"
                          title="Ver Documento"
                          class="text-primary m-r-10"
                          id="Info"
                          style="color: #178719; margin-right: 5px !important"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          @click="openModal(item)"
                        >
                          <span
                            class="fa fa-pencil"
                            style="font-size: 1.6em"
                          ></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          title="Ver Documento"
                          class="text-danger m-r-10"
                          id="Info"
                          style="color: #178719"
                          @click="Delete(item)"
                        >
                          <span
                            class="fa fa-trash-o"
                            style="font-size: 1.6em"
                          ></span>
                        </a>
                      </template>
                      <template v-else>
                        <a
                          href="javascript:void(0)"
                          title="Ver Documento"
                          class="text-success m-r-10"
                          id="Info"
                          style="color: #178719"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          @click="openModal(item)"
                        >
                          <span class="fa fa-gear" style="font-size: 1.6em"></span>
                        </a>
                      </template>
                    </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        </div>
      </div>
    </div>
  
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Nuevo Tuc's
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Empresa</label>
                <div class="">
                <input
                  v-model="filtro.Popup.Empresa"
                  name="Empresa"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refEmpresa"
                  autocomplete="off"
                  disabled/>
              </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Placa</label>
                <div class="">
                  <input
                    v-model="filtro.Popup.Placa"
                    name="Estado"
                    class="form-control input-sm"
                    placeholder=""
                    ref="refClaseBreveteInp"
                    autocomplete="off"
                    disabled/>
                </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                <label class="control-label label-sm">N° Tuc</label>
                <div class="">
                  <input
                    v-model="filtro.Popup.NumTuc"
                    name="NumTuc"
                    class="form-control input-sm"
                    placeholder=""
                    @keyup.enter="nextFocus($refs.refNumAsiento, 'input', 100)"
                    ref="refNumTuc"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">N° Asiento</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.NumAsiento"
                     name="NumAsiento"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refDesdeTucs, 'input', 100)"
                     ref="refNumAsiento"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Desde</label>
                <div class="">
                  <input
                    v-model="filtro.Popup.Desde"
                    name="Estado"
                    type="date"
                    class="form-control input-sm"
                    placeholder=""
                    @keyup.enter="nextFocus($refs.refHastaTucs, 'input', 100)"
                    ref="refDesdeTucs"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Hasta</label>
                <div class="">
                  <input
                    v-model="filtro.Popup.Hasta"
                    name="Hasta"
                    type="date"
                    class="form-control input-sm"
                    placeholder=""
                    @keyup.enter="nextFocus($refs.refNumResolucionTucs, 'input', 100)"
                    ref="refHastaTucs"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Días Faltante</label>
              <div class="">
                <input
                  v-model="filtro.Popup.DiaFaltante"
                  name="DiaFaltante"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDiaFaltante"
                  autocomplete="off"
                  disabled
                />
              </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Estado</label>
              <div class="">
                <template v-if="filtro.Popup.Estado === 'VIGENTE'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #009900;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'VENCIDO'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #e02e2e;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'POR VENCER'">
                  <div
                    class="label label-default"
                    style="
                      text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: rgb(246 137 54);
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
              </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                <label class="control-label label-sm">N° Resolución</label>
                <div class="">
                  <input
                    v-model="filtro.Popup.NumResolucion"
                    name="NumResolucion"
                    class="form-control input-sm"
                    placeholder=""
                    @keyup.enter="nextFocus($refs.refCondVehiculo, 'combo', 200)"
                    ref="refNumResolucionTucs"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Cond. Vehiculo</label>
                <div class="">
                  <v-select
                    v-model="vselect.Popup.CondVehiculo"
                    name="CondVehiculo"
                    :clearable="false"
                    class="v-select-form"
                    label="label"
                    placeholder="--Seleccione--"
                    :options="list.Popup.CondVehiculo"
                    ref="refCondVehiculo"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Largo</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Largo"
                     name="Largo"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refAncho, 'input', 100)"
                     ref="refLargo"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Ancho</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Ancho"
                     name="Ancho"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refAlto, 'input', 100)"
                     ref="refAncho"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Alto</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Alto"
                     name="Alto"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refPeso, 'input', 100)"
                     ref="refAlto"
                     autocomplete="off"/>
                 </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Peso</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Peso"
                     name="Peso"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refCarga, 'input', 100)"
                     ref="refPeso"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Carga</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Carga"
                     name="Carga"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refDocSusti, 'input', 100)"
                     ref="refCarga"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Doc. Sust.</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.DocSusti"
                     name="DocSusti"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refColor, 'input', 100)"
                     ref="refDocSusti"
                     autocomplete="off"/>
                 </div>
              </div>
            </div>
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Color</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.Color"
                     name="Color"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refRutaIns, 'input', 100)"
                     ref="refColor"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Ruta Ins.</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.RutaIns"
                     name="RutaIns"
                     class="form-control input-sm"
                     placeholder=""
                     @keyup.enter="nextFocus($refs.refAñoFabrica, 'input', 100)"
                     ref="refRutaIns"
                     autocomplete="off"/>
                 </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                 <label class="control-label label-sm">Año Fabricación</label>
                 <div class="">
                   <input
                     v-model="filtro.Popup.AñoFabrica"
                     name="AñoFabrica"
                     placeholder="YYYY"
                     @keyup.enter="nextFocus($refs.refBtbGrabar, 'btn', 100)"
                     class="form-control input-sm"
                     ref="refAñoFabrica"
                     maxlength="4"
                     autocomplete="off"/>
                 </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" id="IdCloseTucs">
                <i class="fa fa-close" style="margin-right: 5px;"></i>Close
            </button>
            <button @click="register()" type="button" class="btn btn-primary" ref="refBtbGrabar"><i class="fa fa-save" style="margin-right: 5px;"></i>Grabar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-0" v-show="!vshow">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
     <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
     </button>
    </div>
    <div class="White-Box">
      <AddExcelTucs></AddExcelTucs>
     </div>
   </div>
 </div>

  </template>
  
  <script>
import AddExcelTucs from "../Tucs/AddExcelTucs.vue";
  import { APP } from "../../Content/base";

  export default {
    name: "Tucs",
    el: "#Tucs",
    data() {
      return {
        filtro: {
          Placa: "",
          Popup: {
            IdTucs:0,
            EmpresaId:0,
            VehiculoId:0,
            Empresa: "",
            Placa: "",
            DiaFaltante: "",
            Estado: "",
            Desde:"",
            Hasta: "",
            Largo:"",
            Ancho:"",
            Alto:"",
            Peso:"",
            Carga:"",
            DocSusti:"",
            Color:"",
            RutaIns:"",
            AñoFabrica:"",
            NumTuc:"",
            NumResolucion:"",
            NumAsiento:0,
          },
        },
        list: {
          Empresa: [],
         Estado: [
          { id: 0, label: "NO REGISTRADO" },
          { id: 1, label: "VIGENTE" },
          { id: 2, label: "VENCIDO" },
          { id: 3, label: "POR VENCER" },
         ],
          Popup: {
            CondVehiculo: [{ id: 1, label: "OPERATIVO" },{ id: 2, label: "RESERVA" }],
            Planta: [{ id: 1, label: "PLANTA 1" }],
          },
  
          TablaDetalle: [{ id: 1, label: "esteban", activo: true }],
        },
        vselect: {
          Empresa: null,
          Estado: null,
          Popup: {
            CondVehiculo:null,
          },
        },
        paginate: ["items"],
        per: 10,
        NUM_RESULTS: 10, // Numero de resultados por página
        pag: 1, // Página inicial
        vshow: true,
      };
    },
    async mounted() {
      let _this = this;
    
      await _this.getEmpresa();
    },
    components: {AddExcelTucs},
    methods: {
      nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
      },
      getEmpresa: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Empresa = res.data.Valor;
            _this.vselect.Empresa = _this.list.Empresa[0];
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
      },
      geBusqueda: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      var request = {
        IdEmpresa: _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 1,
        Placa: _this.filtro.Placa || "",
        estado: _this.vselect.Estado ? parseInt(_this.vselect.Estado.id) : -1,
      };

      await this.axios
        .post("api/tucs/ListTUCS", request)
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.TablaDetalle = res.data.Valor;
            if( _this.list.TablaDetalle.length >0){ _this.pag = 1}
           
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
      },
      getFormula: async function (_Fecha) {
      let _this = this;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/revisionTecnica/getFormula/" + _Fecha)
        .then((res) => {
          if (res.data.EsCorrecto) {
            var _Data = res.data.Valor;
            if (_Data) {
              _this.filtro.Popup.DiaFaltante = _Data.diasFaltantes;
              _this.filtro.Popup.Estado = _Data.estado;
            }
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
      },
      openModal: async function (_item) {
      let _this = this;
      setTimeout(async () => {
        _this.cleanField();
        _this.filtro.Popup.IdTucs = _item.TUCId;

        if(_item.TUCId > 0){

          if(_item.AutorizaDesde){
            var sp =   _item.AutorizaDesde.split('/');
            var newValue = sp[2] + '-' + sp[1]+ '-' + sp[0];
            _this.filtro.Popup.Desde = newValue; 
          }

          if(_item.AutorizaHasta){
            var sp2 =   _item.AutorizaHasta.split('/');
            var newValue2 = sp2[2] + '-' + sp2[1]+ '-' + sp2[0];
            _this.filtro.Popup.Hasta = newValue2; 
          }

            
            _this.filtro.Popup.Largo= _item.Largo;
            _this.filtro.Popup.Ancho= _item.Ancho;
            _this.filtro.Popup.Alto= _item.Alto;
            _this.filtro.Popup.Peso= _item.Peso;
            _this.filtro.Popup.Carga= _item.Carga;
            _this.filtro.Popup.DocSusti= _item.DocSustento;
            _this.filtro.Popup.Color= _item.Color;
            _this.filtro.Popup.RutaIns= _item.RutaInscrita;
            _this.filtro.Popup.AñoFabrica= _item.anioFabricacion;
            _this.filtro.Popup.NumTuc= _item.TUCNro;
            _this.filtro.Popup.NumResolucion= _item.NroResolucion;
            _this.filtro.Popup.NumAsiento = _item.NroAsientos;
           _this.vselect.Popup.CondVehiculo = _this.list.Popup.CondVehiculo.find((o)=>{return parseInt(o.id) ===  parseInt(_item.CondicionVehiculo)});
        }

        _this.filtro.Popup.EmpresaId= _item.EmpresaId;
        _this.filtro.Popup.VehiculoId= _item.VehiculoId;
        _this.filtro.Popup.Empresa= _item.Empresa;
        _this.filtro.Popup.Placa= _item.Placa;

           this.nextFocus(this.$refs.refNumTuc, 'input', 200);
       
      }, 300);
      },
      cleanField: function(){
        let _this = this;
             _this.filtro.Popup.IdTucs= 0;
             _this.filtro.Popup.EmpresaId= 0;
             _this.filtro.Popup.VehiculoId= 0;
             _this.filtro.Popup.Empresa= '';
             _this.filtro.Popup.Placa= '';
             _this.filtro.Popup.DiaFaltante= '';
             _this.filtro.Popup.Estado= '';
             _this.filtro.Popup.Desde= '';
             _this.filtro.Popup.Hasta= '';
             _this.filtro.Popup.Largo= '';
             _this.filtro.Popup.Ancho= '';
             _this.filtro.Popup.Alto= '';
             _this.filtro.Popup.Peso= '';
             _this.filtro.Popup.Carga= '';
             _this.filtro.Popup.DocSusti= '';
             _this.filtro.Popup.Color= '';
             _this.filtro.Popup.RutaIns= '';
             _this.filtro.Popup.AñoFabrica= '';
             _this.filtro.Popup.NumTuc= '';
             _this.filtro.Popup.NumResolucion= '';
             _this.filtro.Popup.NumAsiento=0;
           _this.vselect.Popup.CondVehiculo =null;

      },
      register: async function () {
      let _this = this;

      var validar = await _this.validarCampos();
      if(validar){
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
              "TUCId": _this.filtro.Popup.IdTucs > 0 ? parseInt( _this.filtro.Popup.IdTucs) : 0,
              "EmpresaId": parseInt( _this.filtro.Popup.EmpresaId),
              "VehiculoId":_this.filtro.Popup.VehiculoId,
              "TUCNro":_this.filtro.Popup.NumTuc,
              "AutorizaDesde": _this.filtro.Popup.Desde,
              "AutorizaHasta":_this.filtro.Popup.Hasta,
              "NroResolucion": _this.filtro.Popup.NumResolucion,
              "NroAsientos":  parseInt( _this.filtro.Popup.NumAsiento),
              "AnioFabricacion":_this.filtro.Popup.AñoFabrica,
              "NroMotor":"",
              "Largo": parseFloat( _this.filtro.Popup.Largo),
              "Ancho": parseFloat( _this.filtro.Popup.Ancho),
              "Alto": parseFloat( _this.filtro.Popup.Alto),
              "Peso": parseFloat( _this.filtro.Popup.Peso),
              "Carga": parseFloat( _this.filtro.Popup.Carga),
              "Carroceria": "",
              "DocSustento": _this.filtro.Popup.DocSusti,
              "Color": _this.filtro.Popup.Color,
              "RutaInscrita": _this.filtro.Popup.RutaIns,
              "CondicionVehiculo": _this.vselect.Popup.CondVehiculo ? parseInt(_this.vselect.Popup.CondVehiculo.id) : 0,
              "UsrRegistro":usuario ? parseInt(usuario.UsuarioId) : 0,
              "Tipo": _this.filtro.Popup.IdTucs > 0 ? '2' : '1'
          };

          await this.axios
            .post("api/tucs/register", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                document.getElementById('IdCloseTucs').click();
                _this.cleanField();
                await _this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }
      },
      validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.NumTuc === ''){
                  APP.msg.warning( this.$toast,"Ingrese Número Tucs");
                  _this.nextFocus(this.$refs.refNumTuc, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.NumAsiento === ''){
                  APP.msg.warning( this.$toast,"Ingrese Número Asiento");
                  _this.nextFocus(this.$refs.refNumAsiento, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Desde === ''){
                  APP.msg.warning( this.$toast,"Ingrese Desde");
                  _this.nextFocus(this.$refs.refDesdeTucs, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Hasta === ''){
                  APP.msg.warning( this.$toast,"Ingrese Hasta");
                  _this.nextFocus(this.$refs.refHastaTucs, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.NumResolucion === ''){
                  APP.msg.warning( this.$toast,"Ingrese Numero Resolución");
                  _this.nextFocus(this.$refs.refNumResolucionTucs, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.vselect.Popup.CondVehiculo == null && !(_this.vselect.Popup.CondVehiculo && _this.vselect.Popup.CondVehiculo.id)){
                  APP.msg.warning( this.$toast,"Ingrese Condición");
                  _this.nextFocus(this.$refs.refCondVehiculo, 'combo', 200);
                  response = false;
                  return response;
                }

                return response;
      },
      Delete: async function (_item) {
    
      if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/tucs/delete?TUCId=" + (_item.TUCId > 0 ? String(_item.TUCId) : ''))
        .then(async (res) => {
          if (res.data.EsCorrecto) {
            APP.msg.success(this.$toast, "Se elimino correctamente ..!");
            await this.geBusqueda();
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });

  }

      },
      VerImporteMasivo: async function(){
      document.getElementById('IdlimpiarDataTucs').click();
      this.vshow = !this.vshow;
      if(this.vshow){
        await this.geBusqueda();
      }
    }
    },
    watch: {
    "vselect.Empresa": async function (value, oldValue) {
      let _this = this;
      if (value != null && value != oldValue) {
        await _this.geBusqueda();
      }
    },
    "vselect.Estado": async function () {
      let _this = this;
        await _this.geBusqueda();
    },
    "filtro.Placa": async function(){

      if (!(await APP.Func.delayWhileIsSearching())) return;

      await this.geBusqueda();
    },
    "filtro.Popup.Hasta": async function (value) {
      let _this = this;
      if (value != null && value.length == 10) {
        await _this.getFormula(value);
      }
    },
    },
  };
  </script>
  
  <style  scoped>
  </style>