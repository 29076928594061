<template>
<div class="p-0" v-show="vshowSoat">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="geBusqueda()" class="btn btn-secondary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Descargar Plantilla
      </button>
      <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Masivo Xml
      </button>
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
            >
            </v-select>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
          <label class="control-label label-sm">PLACA</label>
          <div class="">
            <input
              v-model="filtro.Placa"
              name="Placa"
              class="form-control input-sm"
              placeholder=""
              ref="refPlaca"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">ESTADO</label>
          <div class="">
            <v-select
              v-model="vselect.Estado"
              name="Estado"
              :clearable="true"
              class="v-select-form"
              label="label"
              placeholder="--Seleccione estado--"
              :options="list.Estado"
              ref="refEstado"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="
            col-md-12 col-sm-12 col-xs-12
            p-l-0 p-r-0
            m-b-10
            paginate-padding
          "
          style="overflow-x: auto"
        >
          <table
            class="
              table table-responsive table-hover table-bordered
              custom-table
            "
          >
            <thead>
              <tr>
                <th>EMPRESA</th>
                <th>PLACA</th>
                <th>N° POLIZA</th>
                <th>ASEGURADORA</th>
                <th>FECHA C.</th>
                <th>DESDE</th>
                <th>HASTA</th>
                <th>ACTIVO</th>
                <th>ESTADO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show="
                  (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key
                "
               >
                <td class="text-left">{{ item.Empresa }}</td>
                <td class="text-center">{{ item.Placa }}</td>
                <td class="text-left">{{ item.NroPoliza }}</td>
                <td class="text-left">{{ item.Aseguradora }}</td>
                <td class="text-left">{{ item.FechaContrato }}</td>
                <td class="text-left">{{ item.VigenciaDel }}</td>
                <td class="text-left">{{ item.VigenciaAl }}</td>
                <td class="text-left">
                  <template v-if="item.Activo  == true">
                    ACTIVO
                  </template>
                  <template v-else>
                    INACTIVO
                  </template>
                </td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.estado === 'VIGENTE'">
                    <div
                      class="label label-default"
                      style=" color: white; background-color: #009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'VENCIDO'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: #e02e2e;
                        padding: 3px 10px;
                        border-radius: 5px;"
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'POR VENCER'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: rgb(246 137 54);
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'NO REGISTRADO'">
                    <div
                      class="label label-default"
                      style="
                        color: white;
                        background-color: #8d8d8d;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                </td>
                <td class="text-center" style="text-align: center">
                  <template v-if="item.IdSeguro > 0">
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span
                        class="fa fa-pencil"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)"
                    >
                      <span
                        class="fa fa-trash-o"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                  </template>
                  <template v-else>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-success m-r-10"
                      id="Info"
                      style="color: #178719"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span class="fa fa-gear" style="font-size: 1.6em"></span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Nuevo Soat/Seguro Vehicular
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Empresa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Empresa"
                  name="Empresa"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refEmpresa"
                  autocomplete="off"
                  disabled/>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Placa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Placa"
                  name="Estado"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refClaseBreveteInp"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">N° Poliza</label>
              <div class="">
                <input
                  v-model="filtro.Popup.NumPoliza"
                  name="NumPoliza"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refFechaContrato, 'input', 100)"
                  ref="refNumPoliza"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Fecha Contrato</label>
              <div class="">
                <input
                  v-model="filtro.Popup.FechaContrato"
                  name="FechaContrato"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refDesde, 'input', 100)"
                  ref="refFechaContrato"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Desde</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Desde"
                  name="Estado"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refAseguradora, 'combo', 100)"
                  ref="refDesde"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Aseguradora</label>
              <div class="">
                <v-select
                  v-model="vselect.Popup.Aseguradora"
                  name="Aseguradora"
                  :clearable="false"
                  class="v-select-form"
                  label="Descripcion"
                  placeholder="--Seleccione--"
                  @keyup.enter="nextFocus($refs.refHasta, 'input', 100)"
                  :options="list.Popup.Aseguradora"
                  ref="refAseguradora"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Hasta</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Hasta"
                  name="Hasta"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.btnGrabar1, 'btn', 100)"
                  ref="refHasta"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Días Faltante</label>
              <div class="">
                <input
                  v-model="filtro.Popup.DiaFaltante"
                  name="DiaFaltante"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDiaFaltante"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Estado</label>
              <div class="">
                <template v-if="filtro.Popup.Estado === 'VIGENTE'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #009900;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'VENCIDO'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #e02e2e;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'POR VENCER'">
                  <div
                    class="label label-default"
                    style="
                      text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: rgb(246 137 54);
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-10 col-sm-10 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Glosa</label>
              <div class="">
                <textarea
                  v-model="filtro.Popup.Glosa"
                  name="Glosa"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refGlosa"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
              <label class="control-label label-sm">&nbsp;</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Activo"
                  name="Activo"
                  id="IdActivo"
                  type="checkbox"
                  ref="refActivo"
                  style="margin-right: 5px;"
                />
                <label for="IdActivo" class="control-label label-sm">Activo</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModal1" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="register()" type="button" class="btn btn-primary" ref="btnGrabar1">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  <div class="p-0" v-show="!vshowSoat">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
     <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
     </button>
    </div>
    <div class="White-Box">
      <AddExcelSoat></AddExcelSoat>
     </div>
  </div>
</div>
 

</template>
  
<script>
import AddExcelSoat from "../SoatSegurioVeh/AddExcelSoat.vue";
import { APP } from "../../Content/base";

export default {
  name: "SoatSeguroVehicular",
  el: "#SoatSeguroVehicular",
  data() {
    return {
      filtro: {
        Placa: "",
        Popup: {
          IdSeguro:0,
          EmpresaId:0,
          VehiculoId:0,
          Empresa: "",
          Placa: "",
          NumPoliza: "",
          FechaContrato: "",
          Desde: "",
          Hasta: "",
          DiaFaltante: "",
          Estado: "",
          Glosa: "",
          Activo: true,
        },
      },
      list: {
        Empresa: [],
        Estado: [
          { id: 0, label: "NO REGISTRADO" },
          { id: 1, label: "VIGENTE" },
          { id: 2, label: "VENCIDO" },
          { id: 3, label: "POR VENCER" },
        ],
        Popup: {
          Aseguradora:[],
        },

        TablaDetalle: [],
      },
      vselect: {
        Empresa: null,
        Estado: null,
        Popup: {
          Aseguradora: null,
        },
      },
      paginate: ["items"],
      per: 10,
      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
      vshowSoat: true,
    };
  },
  async mounted() {
    let _this = this;
    
    await _this.getEmpresa();
    await _this.getAseguradora();
  },
  components: {AddExcelSoat},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getEmpresa: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Empresa = res.data.Valor;
            _this.vselect.Empresa = _this.list.Empresa[0];
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    getAseguradora: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListAseguradora")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Popup.Aseguradora = res.data.Valor;
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    geBusqueda: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      var request = {
        IdEmpresa: _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 1,
        Placa: _this.filtro.Placa || "",
        estado: _this.vselect.Estado ? parseInt(_this.vselect.Estado.id) : -1,
      };

      await this.axios
        .post("api/SoatXVehiculo/ListSeguroSoatXVehiculo", request)
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.TablaDetalle = res.data.Valor;
            if( _this.list.TablaDetalle.length >0){
            _this.pag = 1}
            //APP.msg.info(this.$toast, "Se encontro correctamente ..!");
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    getFormula: async function (_Fecha) {
      let _this = this;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/revisionTecnica/getFormula/" + _Fecha)
        .then((res) => {
          if (res.data.EsCorrecto) {
            var _Data = res.data.Valor;
            if (_Data) {
              _this.filtro.Popup.DiaFaltante = _Data.diasFaltantes;
              _this.filtro.Popup.Estado = _Data.estado;
            }
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    openModal: async function (_item) {
      let _this = this;
      setTimeout(async () => {
        _this.cleanField();
        _this.filtro.Popup.IdSeguro = _item.IdSeguro;

        if(_item.IdSeguro > 0){
         // await _this.getPlanta(_item.PlantaRevisionId);
         // _this.filtro.Popup.PlantaRevisionId = _item.PlantaRevisionId;

          if(_item.FechaContrato){
            var sp1 =   _item.FechaContrato.split('/');
            var newValue1 = sp1[2] + '-' + sp1[1]+ '-' + sp1[0];
            _this.filtro.Popup.FechaContrato = newValue1; 
          }

          if(_item.VigenciaDel){
            var sp =   _item.VigenciaDel.split('/');
            var newValue = sp[2] + '-' + sp[1]+ '-' + sp[0];
            _this.filtro.Popup.Desde = newValue; 
          }

          if(_item.VigenciaAl){
            var sp2 =   _item.VigenciaAl.split('/');
            var newValue2 = sp2[2] + '-' + sp2[1]+ '-' + sp2[0];
            _this.filtro.Popup.Hasta = newValue2; 
          }

          _this.filtro.Popup.NumPoliza= _item.NroPoliza;
          _this.filtro.Popup.Estado= _item.estado;
           _this.filtro.Popup.Glosa= _item.Glosa;
           _this.filtro.Popup.Activo= _item.Activo;
           _this.vselect.Popup.Aseguradora = _this.list.Popup.Aseguradora.find((o)=>{return parseInt(o.Id) ===  parseInt(_item.AseguradoraId)});
          // _this.vselect.Popup.Aseguradora = this._.find(_this.list.Popup.Aseguradora, (o)=>{return parseInt(o.Id) ===  parseInt(_item.AseguradoraId)})
        }

           _this.filtro.Popup.EmpresaId = _item.EmpresaId;
           _this.filtro.Popup.Empresa= _item.Empresa;
           _this.filtro.Popup.VehiculoId= _item.VehiculoId;
           _this.filtro.Popup.Placa= _item.Placa;

           this.nextFocus(this.$refs.refNumPoliza, 'input', 200);
       
      }, 300);
      // _this.vselect.Popup.Planta = _this.list.Popup.Planta.find((o)=>{ return parseInt(o.Id) === parseInt(_item.PlantaRevisionId)});
      //_this.vselect.Popup.Planta = _.find(_this.list.Popup.Planta, (o)=>{ return parseInt(o.Id) === parseInt(_item.PlantaRevisionId) });
    },
    cleanField: function(){
        let _this = this;

           _this.filtro.Popup.IdSeguro= 0;
           _this.filtro.Popup.EmpresaId= 0;
           _this.filtro.Popup.VehiculoId= 0;
           _this.filtro.Popup.Empresa= '';
           _this.filtro.Popup.Placa= '';
           _this.filtro.Popup.NumPoliza= '';
           _this.filtro.Popup.FechaContrato= '';
           _this.filtro.Popup.Desde= '';
           _this.filtro.Popup.Hasta= '';
           _this.filtro.Popup.DiaFaltante= '';
           _this.filtro.Popup.Estado= '';
           _this.filtro.Popup.Glosa= '';
           _this.filtro.Popup.Activo= true;
           _this.vselect.Popup.Aseguradora =null;

    },
    register: async function () {
      let _this = this;

      var validar = await _this.validarCampos();
      if(validar){
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "IdSeguro": _this.filtro.Popup.IdSeguro > 0 ? parseInt( _this.filtro.Popup.IdSeguro) : 0,
            "IdSeguroVehiculo": 0,
            "EmpresaId": _this.filtro.Popup.EmpresaId,
            "FechaContrato": _this.filtro.Popup.FechaContrato,
            "VigenciaDel":_this.filtro.Popup.Desde,
            "VigenciaAl":  _this.filtro.Popup.Hasta,
            "SeguroTipoId": 1,
            "NroPoliza":_this.filtro.Popup.NumPoliza,
            "AseguradoraId": _this.vselect.Popup.Aseguradora ? parseInt(_this.vselect.Popup.Aseguradora.Id) : 0,
            "Glosa": _this.filtro.Popup.Glosa,
            "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
            "Activo":  _this.filtro.Popup.Activo,
            "VehiculoId": _this.filtro.Popup.VehiculoId,
            "Tipo": _this.filtro.Popup.IdSeguro > 0 ? '2' : '1'
          };

          await this.axios
            .post("api/SoatXVehiculo/registerUpdate", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                document.getElementById('closeModal1').click();
                _this.cleanField();
                await _this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }
    },
    Delete: async function (_item) {
    
        if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

          this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          await this.axios
            .get("api/SoatXVehiculo/delete/" + (_item.IdSeguro > 0 ? String(_item.IdSeguro) : ''))
            .then(async (res) => {
              if (res.data.EsCorrecto) {
                APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                await this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });

      }

    },
    validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.NumPoliza === ''){
                  APP.msg.warning( this.$toast,"Ingrese Número Poliza");
                  _this.nextFocus(this.$refs.refNumPoliza, 'input', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.FechaContrato === ''){
                  APP.msg.warning( this.$toast,"Ingrese Fecha Contrato");
                  _this.nextFocus(this.$refs.refFechaContrato, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Desde === ''){
                  APP.msg.warning( this.$toast,"Ingrese Desde");
                  _this.nextFocus(this.$refs.refDesde, 'input', 200);
                  response = false;
                  return response;
                }

                if( _this.vselect.Popup.Aseguradora == null && !(_this.vselect.Popup.Aseguradora && _this.vselect.Popup.Aseguradora.Id)){
                  APP.msg.warning( this.$toast,"Ingrese Aseguradora");
                  _this.nextFocus(this.$refs.refAseguradora, 'combo', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.Hasta === ''){
                  APP.msg.warning( this.$toast,"Ingrese Hasta");
                  _this.nextFocus(this.$refs.refHasta, 'input', 200);
                  response = false;
                  return response;
                }
                
                return response;
    },
    VerImporteMasivo: async function(){
      document.getElementById('IdlimpiarDataSoat').click();
      this.vshowSoat = !this.vshowSoat;
      if(this.vshowSoat){
        await this.geBusqueda();
      }
    }
  },
  watch: {
    "vselect.Empresa": async function (value, oldValue) {
      let _this = this;
      if (value != null && value != oldValue) {
        await _this.geBusqueda();
      }
    },
    "vselect.Estado": async function () {
      let _this = this;
        await _this.geBusqueda();
    },
    "filtro.Placa": async function(){

      if (!(await APP.Func.delayWhileIsSearching())) return;

      await this.geBusqueda();
    },
    "filtro.Popup.Hasta": async function (value) {
      let _this = this;
      if (value != null && value.length == 10) {
        await _this.getFormula(value);
      }
    },
  },
};
</script>
  
  <style  scoped>
</style>