<template>
    <div class="p-0" v-show="vshow">
     <div class="Container">
       <div style="margin-bottom: 5px !important">
      <button  data-bs-toggle="modal" data-bs-target="#exampleModalRangoFe" class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-download"></i></span
        >Descargar Viajes
      </button>
      <button  @click="VerImporteMasivo()" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Registros
      </button>
      <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
       </div>
       <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
              disabled>
            </v-select>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">N° De Viaje</label>
          <div class="">
            <input
              v-model="filtro.NumViaje"
              name="NumViaje"
              class="form-control input-sm"
              placeholder=""
              ref="refNumViaje"
              autocomplete="off"
            />
          </div>
        </div>
       
      </div>
      <div class="row">
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0  m-b-10 paginate-padding" style="overflow-x: auto" >
          <table  class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>NUMERO VIAJE</th>
                <th>CONDUCTOR</th>
                <th>PLACA</th>
                <th>SUPERVISOR</th>
                <th>FECHA GEN. VIAJE</th>
                <th>KM INICIAL</th>
                <th>KM FINAL</th>
                <th>KM SCANNER</th>
                <th>GLN SCANNER</th>
                <th>GLN ESTACION</th>
                <th>SERIE VALE MANUAL</th>
                <th>NUMERO VALE MANUAL</th>
                <th>FECHA VALE MANUAL</th>
                <th>SERIE VALE JELAF</th>
                <th>NUMERO VALE JELAF</th>
                <th>FECHA VALE JELAF</th>
                <th>LITROS UREA</th>
                <th>FECHA VALE UREA JELAF</th>
                <th>SERIE VALE UREA JELAF</th>
                <th>NUMERO VALE UREA JELAF</th>
                <th>SERIE VALE UREA MANUAL</th>
                <th>NUMERO VALE UREA MANUAL</th>
                <th>FECHA REGISTER</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.NUMERO_VIAJE }}</td>
                <td class="text-left">{{ item.CONDUCTOR }}</td>
                <td class="text-left">{{ item.PLACA }}</td>
                <td class="text-left">{{ item.SUPERVISOR }}</td>
                <td class="text-left">{{ item.FECHA_GENERAVIAJE }}</td>
                <td class="text-left">{{ item.KM_INICIAL }}</td>
                <td class="text-left">{{ item.KM_FINAL }}</td>
                <td class="text-left">{{ item.KM_SCANNER }}</td>
                <td class="text-left">{{ item.GLN_SCANNER }}</td>
                <td class="text-left">{{ item.GLN_ESTACION }}</td>
                <td class="text-left">{{ item.SERIE_VALE_MANUAL }}</td>
                <td class="text-left">{{ item.NUMERO_VALE_MANUAL }}</td>
                <td class="text-left">{{ item.FECHA_VALE_MANUAL }}</td>
                <td class="text-left">{{ item.SERIE_VALE_JELAF }}</td>
                <td class="text-left">{{ item.NUMERO_VALE_JELAF }}</td>
                <td class="text-left">{{ item.FECHA_VALE_JELAF }}</td>
                <td class="text-left">{{ item.LITROS_UREA }}</td>
                <td class="text-left">{{ item.FECHAVALE_UREAJELAF }}</td>
                <td class="text-left">{{ item.SERIEVALE_UREAJELAF }}</td>
                <td class="text-left">{{ item.NUMEROVALE_UREAJELAF }}</td>
                <td class="text-left">{{ item.SERIEVALE_UREAMANUAL }}</td>
                <td class="text-left">{{ item.NUMEROVALE_UREAMANUAL }}</td>
                <td class="text-left">{{ item.Fech_RegisterApoyo }}</td>
                <!-- <td class="text-center" style="text-align: center">
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      @click="openVista(item)" >
                      <span class="fa fa-pencil" style="font-size: 1.6em" ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item.IdConfig)">
                      <span  class="fa fa-trash-o" style="font-size: 1.6em" ></span>
                    </a>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important">
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
       </div>
      </div>

<!-- Modal -->
<div
    class="modal fade"
    id="exampleModalRangoFe"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalRangoFeLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalRangoFeLabel">
            Rango de Fechas
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="CloseModalRegFech"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Desde</label>
              <div class="">
                <input
                  v-model="filtro.Popup.FechaDesde"
                  name="FechaDesde"
                  class="form-control input-sm"
                  placeholder=""
                  type="date"
                  ref="refFechaDesde"
                  autocomplete="off"/>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Hasta</label>
              <div class="">
                <input
                  v-model="filtro.Popup.FechaHasta"
                  name="FechaHasta"
                  class="form-control input-sm"
                  placeholder=""
                  type="date"
                  ref="refFechaHasta"
                  autocomplete="off"/>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button @click="cleanField()" type="button" class="btn btn-danger" id="closeModal2" data-bs-dismiss="modal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button> -->
          <button @click="geDescargaPlantilla()" type="button" class="btn btn-primary" ref="btnGrabar2">
            <i class="fa fa-save" style="margin-right: 5px"></i>Descargar Viajes
          </button>
        </div>
      </div>
    </div>
  </div>


    </div>

    <div class="p-0" v-show="!vshow">
        <div class="Container">
            <div style="margin-bottom: 5px !important">
            <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
                    <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
            </button>
            </div>
            <div class="White-Box">
            <AddExcelApoyo></AddExcelApoyo>
            </div>
        </div>
    </div>
</template>

<script>
    import { APP } from "../../Content/base";
    import AddExcelApoyo from "../OperApoyo/AddExcelApoyo.vue"

    export default {
        name: "appVueApoyo",
        el: "#ControlFlota",
        data() {
           return {
             filtro: {
               NumViaje: "",
               Popup:{
                FechaDesde:'',
                FechaHasta:'',
               }
             },
             list: {
               Empresa: [],
               TablaDetalle: [],

             },
             vselect: {
               Empresa: null,
             },
             NUM_RESULTS: 10, // Numero de resultados por página
             pag: 1, // Página inicial
             vshow: true,
           };
        },
        async mounted() {
           let _this = this;
           
           await _this.getEmpresa();
           await _this.geBusqueda();
        },
        components: {AddExcelApoyo},
        methods: {
           nextFocus: async function (ref, type, time = 100) {
             setTimeout(() => {
               switch (type) {
                 case "input":
                   ref.focus();
                   break;
                 case "money":
                   ref.$refs.money.focus();
                   break;
                 case "radio":
                   ref.$refs.input.focus();
                   break;
                 case "check":
                   ref.$refs.input.focus();
                   break;
                 case "combo":
                   ref.$refs.search.focus();
                   break;
                 case "btn":
                   ref.focus();
                   break;
                 default:
                   ref();
               }
             }, time);
           },
           getEmpresa: async function () {
             let _this = this;
       
             this.axios.defaults.headers.common["Authorization"] =
               "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
       
             await this.axios
               .get("api/netcore/ListEmpresa")
               .then((res) => {
                 if (res.data.EsCorrecto) {
                   _this.list.Empresa = res.data.Valor;
                   _this.vselect.Empresa = _this.list.Empresa[0];
                 } else {
                   APP.msg.error(this.$toast, res.data.Mensaje);
                 }
               })
               .catch((error) => {
                 APP.msg.error(
                   this.$toast,
                   "Token expirado Inicie session..!!" + error
                 );
                 localStorage.clear();
                 this.$store.state.isLogeado = false;
                 this.$router.push("/");
               });
           },
           geBusqueda: async function () {
                   let _this = this;
       
                   this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
       
                   var request = {
                    "IdEmpresa": _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 0,
                    "NumeroViaje": _this.filtro.NumViaje,
                   };
       
                   await this.axios
                       .post("api/operaciones/admin/ListOperacionesComplete", request)
                       .then((res) => {
                           if (res.data.EsCorrecto) {
       
                               _this.list.TablaDetalle = res.data.Valor;
                               if( _this.list.TablaDetalle.length >0){
                                   _this.pag = 1
                                 }
       
                           } else { APP.msg.error(this.$toast, res.data.Mensaje); }
                       })
                       .catch((error) => {
                             APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
                             localStorage.clear();
                             this.$store.state.isLogeado = false;
                             this.$router.push("/");
                       });
           },
           Delete: async function (_IdConfig) {
               
                       if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){
       
                       this.axios.defaults.headers.common["Authorization"] =
                       "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
       
                       await this.axios
                           .get("api/configuracion/delete?IdConfig=" + _IdConfig)
                           .then(async (res) => {
                           if (res.data.EsCorrecto) {
                               APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                               await this.geBusqueda();
                           } else {
                               APP.msg.error(this.$toast, res.data.Mensaje);
                           }
                           })
                           .catch((error) => {
                               APP.msg.error(
                                   this.$toast,
                                   "Token expirado Inicie session..!!" + error
                               );
                               localStorage.clear();
                               this.$store.state.isLogeado = false;
                               this.$router.push("/");
                           });
       
                   }
       
           },
           AddNew: async function(){
             this.$refs.refAddNotifica.cleanGrabado();
             this.vshowAdd = !this.vshowAdd;
             if(this.vshowAdd){
               await this.geBusqueda();
             }
           },
           VerImporteMasivo: async function(){
              document.getElementById('IdLimpDtaApoyo').click();
                this.vshow = !this.vshow;
                if(this.vshow){
                  await this.geBusqueda();
               }
            },
           geDescargaPlantilla: async function () {
            let _this = this;
            if(_this.filtro.Popup.FechaDesde != ''){
              if(_this.filtro.Popup.FechaHasta != ''){

                   this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                   var sep1 = _this.filtro.Popup.FechaDesde.split('-');
                   var Fecha1 = sep1[2] + '/' + sep1[1] + '/' + sep1[0];

                   var sep2 = _this.filtro.Popup.FechaHasta.split('-');
                   var Fecha2 = sep2[2] + '/' + sep2[1] + '/' + sep2[0];

                   var request = {
                    "FechaInicial": Fecha1,
                    "FechaFinal": Fecha2, 
                   };
       
                   await this.axios
                       .post("api/operaciones/admin/getReportApoyo", request)
                       .then((res) => {
                           if (res.data.EsCorrecto) {
       
                            APP.Func.decodeExcel(res.data.Valor,"PlantillaApoyo");
                            _this.filtro.Popup.FechaDesde = '';
                            _this.filtro.Popup.FechaHasta = '';
                            document.getElementById('CloseModalRegFech').click();

                           } else { APP.msg.error(this.$toast, res.data.Mensaje); }
                       })
                       .catch((error) => {
                             APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
                             localStorage.clear();
                             this.$store.state.isLogeado = false;
                             this.$router.push("/");
                       });
              }else{
                APP.msg.warning(this.$toast, "Ingrese Fecha Hasta");
                _this.nextFocus(_this.$refs.refFechaHasta, 'input', 200);
              }
            }else{
              APP.msg.warning(this.$toast, "Ingrese Fecha Desde");
              _this.nextFocus(_this.$refs.refFechaDesde, 'input', 200);
            }
           },
        },
        watch: {
        },

    }
</script>

<style lang="scss" scoped>

</style>