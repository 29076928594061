<template>

  <LayoutSidebar
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start'
    ]"
    v-if="this.$store.state.showSidenav"
  />

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :class="[this.$store.state.showSidenav ? (this.$store.state.isPinned ? 'medidaSep':'medidaSepO' ):'medidaSepO']"
    style="background: #edf1f5 !important;"
  >
    <!-- nav -->
    <LayoutHeader
      :class="[navClasses]"
      :textWhite="
        this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'
      "
      :minNav="navbarMinimize"
      v-show="this.$store.state.showNavbar"
    />
    <router-view />
    <LayoutFooter v-show="this.$store.state.showFooter" />
  </main>





  
</template>

<script>
import LayoutSidebar from "./Sharedx/_LayoutSidebar.vue";
import LayoutFooter from "./Sharedx/_LayoutFooter.vue";
import LayoutHeader from "./Sharedx/_LayoutHeader.vue";
import { mapMutations } from "vuex";
 
export default {
  name: "App",
  components: {
    LayoutSidebar,
    LayoutFooter,
    LayoutHeader
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  },
  created(){
   
  },
  mounted(){
      // var modal = document.getElementById('exampleModal');
      // modal.className='modal fade';
  },
  computed: {
    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && !this.$store.state.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && this.$store.state.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute
      };
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  }
};
</script>

<style>
.main-content, .sidenav {
    transition: all 0.2s ease-in-out;
}
.border-radius-lg {
    border-radius: 0.75rem;
}
.max-height-vh-100 {
    max-height: 100vh !important;
}
.h-100 {
    height: 100% !important;
}
.position-relative {
    position: relative !important;
}



.medidaSep {
    margin-left: 15.65rem !important;
}

.medidaSepO {
    margin-left: 0rem !important;
}

@media (max-width: 700px) {
    .medidaSep {
        margin-left: 0rem !important;
    }

    .verLista {
        display: none !important;
    }
}


</style>
