<template>
  <div class="Container">
    <!-- <div style="margin-bottom: 5px !important">
        <a @click="openFileManager2" style="margin-right: 10px; background-color: green; border-color: green;" class="btn btn-success btn-sm waves-effect waves-light">
            <span class="visible-lg visible-md">
                <i class="fa fa-file-excel-o fa-1.5x fa-fw"></i>
                Cargar Productos<input @change="readExcel($event)" id="file-upload-2" type="file" class="hidden" accept=".xlsx, .xls, .xlsm" style="visibility: hidden;"/>
            </span>
            <span class="visible-xs visible-sm"><i class="fa fa-file-excel-o fa-1.5x"></i></span>
        </a>

    </div> -->
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="drop-area"  id="dropAreaDrag">
                <i style="margin-top: 30px; color: #1fa71f !important;" class="fa fa-file-excel-o fa-4x"></i>
                <h2>Suelta aqui el archivo o seleccionalo</h2>
                <button @click="$refs.refInputLogo.click()">Seleccionar archivo</button>
                <input type="file" @change="readExcel($event)" name="" id="input-file" ref="refInputLogo" accept=".xlsx, .xls, .xlsm" hidden multiple style="visibility: hidden;" />
            </div>
            <div id="preview"></div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
//import * as XLSX from 'xlsx'





export default {
  name: "ImportacionMasivo",
  el: "#ImportacionMasivo",
  data() {
    return {
      filtro: {
        Placa: "",
      },
      list: {
        TablaDetalle: [],
      },
      vselect: {},

      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
    };
  },
  async mounted() {
    let _this = this;
    document.addEventListener("dragover", function (event) {
     event.preventDefault();
     document.getElementById('dropAreaDrag').classList.add('active');
    });

    document.addEventListener("dragleave", function (event) {
     if (event.target.className == "dropAreaDrag") {
         event.target.style.border = "";
     }
    });

    document.addEventListener("drop", async function (event) {
     event.preventDefault();
         await _this.readExcel(event, 1);
         document.getElementById('dropAreaDrag').classList.remove('active');
    
   });
    document.addEventListener("dropAreaDrag", function (event) {
     if (event.target.className == "droptarget") {
         event.target.style.border = "3px dotted red";
     }
   });
//    document.addEventListener("change", (e) => {
//       e.dataTransfer.files[0];
//        document.getElementById('dropAreaDrag').classList.add('active');
//    });
  },
  components: {},
  methods: {

    openFileManager2: function () {
        document.getElementById('file-upload-2').click();
    },
    readExcel: async function () {
        // var file = null;
        //    if(t == 1)
        //     file = e.dataTransfer.files[0];
        //    else
        //     file = e.target.files[0];
        //     //const file = e.target.files[0];
        //     const promise = new Promise((resolve, reject) => {
        //         const fileReader = new FileReader();
        //         fileReader.readAsArrayBuffer(file);
        //         fileReader.onload = async (e) => {
        //            const bufferArray = e.target.result;
        //            const wb = XLSX.read(bufferArray, { type: "buffer" });
        //            const wsname = wb.SheetNames[0];
        //            const ws = wb.Sheets[wsname];
        //            const data = XLSX.utils.sheet_to_json(ws);   
                  
        //            console.log(data);
                  
        //         };
        //         fileReader.onerror = (error) => {
        //             reject(error);
        //         };
        //     });
        //     promise.then((d) => {
        //         alert(d);
        //     });
    },

  },
};
</script>

<style scoped>
.drop-area {
            border: 5px dashed #ddd;
            height: 230px;
            width: 100%;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

            .drop-area.active {
                background-color: #b8d4fe;
                color: black;
                border: 2px dashed #618ac9;
            }

            .drop-area h2 {
                font-size: 20px;
                font-weight: 500;
                color: #000;
            }

            .drop-area span {
                font-size: 25px;
                color: #000;
            }

            .drop-area button {
                padding: 10px 25px;
                font-size: 17px;
                border: 0px;
                outline: none !important;
                background-color: #5256ad;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                margin: 20px;
            }

        .file-container {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            border: solid 1px #ddd;
        }

        #preview {
            margin-top: 10px;
        }



</style>