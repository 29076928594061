<template>
    <div class="p-0" v-show="vshowAdd">
      <div class="Container">
       <div style="margin-bottom: 5px !important">
      <button  @click="AddNew" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Nueva Configuración
      </button>
      <button @click="geBusqueda()" class="btn btn-primary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button  class="btn btn-info" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-envelope-o"></i></span
        >Enviar Notificación
      </button>
     
       </div>
       <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
              disabled>
            </v-select>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
          <label class="control-label label-sm">USUARIO</label>
          <div class="">
            <input
              v-model="filtro.Usuario"
              name="Usuario"
              class="form-control input-sm"
              placeholder=""
              ref="refUsuario"
              autocomplete="off"
              disabled/>
            <!-- <v-select
              v-model="vselect.Usuario"
              name="Usuario"
              :clearable="true"
              class="v-select-form"
              label="label"
              placeholder="--Seleccione--"
              :options="list.Usuario"
              ref="refUsuario"
            >
            </v-select> -->
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">DESCRIPCION</label>
          <div class="">
            <input
              v-model="filtro.Descripcion"
              name="Descripcion"
              class="form-control input-sm"
              placeholder=""
              ref="refDescripcion"
              autocomplete="off"
            />
          </div>
        </div>
       
      </div>
      <div class="row">
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0  m-b-10 paginate-padding" style="overflow-x: auto" >
          <table  class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>CONFIGURACIÓN</th>
                <!-- <th>EMPRESA(S)</th> -->
                <th>REGISTRADO</th>
                <th>FECHA REGISTRO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show="(pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.Descripcion }}</td>
                <td class="text-left">{{ item.Usuario }}</td>
                <td class="text-center">{{ item.FechaRegistro }}</td>
                <td class="text-center" style="text-align: center">
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      @click="openVista(item)" >
                      <span class="fa fa-pencil" style="font-size: 1.6em" ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item.IdConfig)">
                      <span  class="fa fa-trash-o" style="font-size: 1.6em" ></span>
                    </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important">
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
       </div>
      </div>
    </div>

    <div class="p-0" v-show="!vshowAdd">
      <div class="Container">
        <div style="margin-bottom: 5px !important">
         <button  @click="AddNew" class="btn btn-success" style="margin-right: 3px !important">
                   <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
         </button>
        </div>
        <div class="White-Box">
               <appVueAddNotificacionAlerta ref="refAddNotifica"></appVueAddNotificacionAlerta>
         </div>
      </div>
    </div>
</template>

<script>
import appVueAddNotificacionAlerta from "../NotificacionAlerta/AddNotificacionAlerta.vue"
import { APP } from "../../Content/base";

export default {
  name:"appVueNotificacionAlerta",
  el: "#appVueNotificacionAlerta",
  data() {
    return {
      filtro: {
        Descripcion: "",
        Usuario: "",
      },
      list: {
        Empresa: [],
        TablaDetalle: [],
      },
      vselect: {
        Empresa: null,
      },
      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
      vshowAdd: true,
    };
  },
  async mounted() {
    let _this = this;
    
    await _this.getEmpresa();
    await _this.geBusqueda();

    var usuario = JSON.parse(localStorage.getItem("Usuario"));
   _this.filtro.Usuario = usuario.Usuario;
  },
  components: {appVueAddNotificacionAlerta},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getEmpresa: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Empresa = res.data.Valor;
            _this.vselect.Empresa = _this.list.Empresa[0];
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    geBusqueda: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            var request = {
              "Descripcion": _this.filtro.Descripcion,
              "Usuario": 0
            };

            await this.axios
                .post("api/configuracion/ListConfiguraciones", request)
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.TablaDetalle = res.data.Valor;
                        if( _this.list.TablaDetalle.length >0){
                            _this.pag = 1
                          }

                    } else { APP.msg.error(this.$toast, res.data.Mensaje); }
                })
                .catch((error) => {
                      APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
                      localStorage.clear();
                      this.$store.state.isLogeado = false;
                      this.$router.push("/");
                });
    },
    Delete: async function (_IdConfig) {
        
                if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

                this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                await this.axios
                    .get("api/configuracion/delete?IdConfig=" + _IdConfig)
                    .then(async (res) => {
                    if (res.data.EsCorrecto) {
                        APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                        await this.geBusqueda();
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                    .catch((error) => {
                        APP.msg.error(
                            this.$toast,
                            "Token expirado Inicie session..!!" + error
                        );
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });

            }

    },
    AddNew: async function(){
      this.$refs.refAddNotifica.cleanGrabado();
      this.vshowAdd = !this.vshowAdd;
      if(this.vshowAdd){
        await this.geBusqueda();
      }
    },
    openVista: function(_Item){
      this.$refs.refAddNotifica.Edita(_Item);
      this.vshowAdd = false;
    }
  },
  watch: {
    "vselect.Empresa": async function (value, oldValue) {
     // let _this = this;
      if (value != null && value != oldValue) {
       // await _this.geBusqueda();
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>