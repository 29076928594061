<template>
<div class="p-0" v-show="vshow">
    <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="geBusqueda()" class="btn btn-secondary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Descargar Plantilla
      </button>
      <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Masivo Xml
      </button>
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
            >
            </v-select>
          </div>
        </div>
        <div class="col-md-1 col-sm-1 col-xs-12 separate-in-form">
          <label class="control-label label-sm">PLACA</label>
          <div class="">
            <input
              v-model="filtro.Placa"
              name="PLACA"
              class="form-control input-sm"
              placeholder=""
              ref="refPLACA"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
          <label class="control-label label-sm">&nbsp;</label>
          <div class="">

            <input type="checkbox" id="checkIndicadorTodos" v-model="filtro.IndicadorTodos" style="margin-right: 3px;"/>
            <label for="checkIndicadorTodos" class="control-label label-sm" style="margin-right: 10px;">Todo</label>

            <input type="checkbox" id="checkHerme" v-model="filtro.Indicador1" style="margin-right: 3px;"/>
            <label for="checkHerme" class="control-label label-sm" style="margin-right: 10px;">Hermeticidad</label>

            <input type="checkbox" id="checkIndicador2" v-model="filtro.Indicador2" style="margin-right: 3px;"/>
            <label  for="checkIndicador2" class="control-label label-sm" style="margin-right: 10px;">Cert.Tolva G.m.</label>

            <input type="checkbox" id="checkIndicador3" v-model="filtro.Indicador3" style="margin-right: 3px;"/>
            <label for="checkIndicador3" class="control-label label-sm" style="margin-right: 10px;">Boni. Tolva</label>

            <input type="checkbox" id="checkIndicador4" v-model="filtro.Indicador4" style="margin-right: 3px;"/>
            <label for="checkIndicador4" class="control-label label-sm" style="margin-right: 10px;">Boni. Tracto</label>

            <input type="checkbox" id="checkIndicador5" v-model="filtro.Indicador5" style="margin-right: 3px;"/>
            <label for="checkIndicador5" class="control-label label-sm" style="margin-right: 10px;">Homo. Volcan</label>

            <input type="checkbox" id="checkIndicador6" v-model="filtro.Indicador6" style="margin-right: 3px;"/>
            <label for="checkIndicador6" class="control-label label-sm" style="margin-right: 10px;">Cubicación</label>

            <input type="checkbox" id="checkIndicador7" v-model="filtro.Indicador7" style="margin-right: 3px;"/>
            <label for="checkIndicador7" class="control-label label-sm">Cstancia GPS</label>


          </div>
        </div>
        
      </div>
      <div class="row">
        <div
          class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding " style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>EMPRESA</th>
                <th>PLACA</th>
                <th>TIPO</th>
                <th>FLOTA</th>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador1">
                    <th>HERMETICIDAD</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador2">
                    <th>CERT TOLVAGLOBA</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador3">
                    <th>BONIFICACION-TOLVA</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador4">
                    <th>BONIFICACION-TRACTO</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador5">
                    <th>HOMOLOGACION-VOLCAN</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador6">
                    <th>CUBICACIÓN</th>
                    <th>ESTADO</th>
                </template>
                <template v-if="filtro.IndicadorTodos || filtro.Indicador7">
                    <th>CONSTANCIA GPS</th>
                    <th>ESTADO</th>
                </template>
                <!-- <th>ACCIONES</th> -->
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.TablaDetalle" :key="key + 1"
               v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key">
                <td class="text-left">{{ item.Empresa }}</td>
                <td class="text-center"><a @click="verDetalle(item)" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal">{{ item.Placa }}</a></td>
                <td class="text-left">{{ item.TipoVehiculo }}</td>
                <td class="text-left">{{ item.FlotaVehiculo }}</td>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador1">
                <td class="text-left">{{ item.FechaHermeticidad }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoHermeticidad === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoHermeticidad }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHermeticidad === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoHermeticidad }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHermeticidad === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoHermeticidad }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHermeticidad === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoHermeticidad }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador2">
                <td class="text-left">{{ item.FechaCertTolvaMining }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoCertTolvaMining === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoCertTolvaMining }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCertTolvaMining === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoCertTolvaMining }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCertTolvaMining === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoCertTolvaMining }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCertTolvaMining === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoCertTolvaMining }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador3">
                <td class="text-left">{{ item.FechaBonificaTolva }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoBonificaTolva === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoBonificaTolva }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTolva === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoBonificaTolva }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTolva === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoBonificaTolva }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTolva === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoBonificaTolva }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador4">
                <td class="text-left">{{ item.FechaBonificaTracto }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoBonificaTracto === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoBonificaTracto }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTracto === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoBonificaTracto }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTracto === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoBonificaTracto }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoBonificaTracto === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoBonificaTracto }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador5">
                <td class="text-left">{{ item.FechaHomologaVolcan }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoHomologaVolcan === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoHomologaVolcan }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHomologaVolcan === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoHomologaVolcan }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHomologaVolcan === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoHomologaVolcan }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoHomologaVolcan === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoHomologaVolcan }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador6">
                <td class="text-left">{{ item.FechaCubicacion }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoCubicacion === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoCubicacion }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCubicacion === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoCubicacion }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCubicacion === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoCubicacion }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoCubicacion === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoCubicacion }}
                    </div>
                  </template>
                </td>
               </template>
               <template v-if="filtro.IndicadorTodos || filtro.Indicador7">
                <td class="text-left">{{ item.FechaConsGPS }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.EstadoConsGPS === 'VIGENTE'">
                    <div class="label label-default" style="color: white;background-color:#009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.EstadoConsGPS }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoConsGPS === 'VENCIDO'">
                    <div class="label label-default" style="color: white; background-color: #e02e2e;padding: 3px 10px;border-radius: 5px; ">
                      {{ item.EstadoConsGPS }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoConsGPS === 'POR VENCER'">
                    <div class="label label-default" style="color: white;background-color: rgb(246 137 54);padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoConsGPS }}
                    </div>
                  </template>
                  <template v-else-if="item.EstadoConsGPS === 'NO REGISTRADO'">
                    <div class="label label-default" style="color: white;background-color: #8d8d8d;padding: 3px 10px;border-radius: 5px;">
                      {{ item.EstadoConsGPS }}
                    </div>
                  </template>
                </td>
               </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#" aria-label="Previous" v-show="pag != 1"
                  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px" >
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Indicadores
          </h5>
          <button @click="cleanField()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-9 col-sm-9 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Empresa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Empresa"
                  name="Empresa"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refEmpresa"
                  autocomplete="off"
                  disabled/>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Placa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Placa"
                  name="PlacaP"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refFechaContrato, 'input', 100)"
                  ref="refPlacaP"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
          </div>
          <hr/>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                <label class="control-label label-sm">Indicador</label>
                <div class="">
                    <v-select
                    v-model="vselect.Popup.Indicador"
                    name="IndicadorP"
                    :clearable="false"
                    class="v-select-form"
                    label="Descripcion"
                    placeholder="--Seleccione Indicador--"
                    :options="list.Popup.Indicador"
                    ref="refIndicadorP"
                    >
                    </v-select>
                </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Fecha</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Fecha"
                  name="Fecha"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.refObservacion, 'input', 100)"
                  ref="refFecha"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Días Faltante</label>
              <div class="">
                <input
                  v-model="filtro.Popup.DiaFaltante"
                  name="DiaFaltante"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDiaFaltante"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Estado</label>
              <div class="">
                <template v-if="filtro.Popup.Estado === 'VIGENTE'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white; 
                      background-color: #009900;
                      padding: 3px 10px;  
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'VENCIDO'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #e02e2e;
                      padding: 3px 10px;
                      border-radius: 5px;">
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'POR VENCER'">
                  <div
                    class="label label-default"
                    style="
                      text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: rgb(246 137 54);
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Observación:</label>
              <div class="">
                <textarea
                  v-model="filtro.Popup.Observacion"
                  name="Observacion"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refObservacion"
                  autocomplete="off"
                  @input="filtro.Popup.Observacion = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.btnAgregar, 'btn', 100)"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                <label class="control-label label-sm">&nbsp;</label>
                <div class="" style="text-align: right;">
                    <button @click="register()" type="button" class="btn btn-success" ref="btnAgregar" style="margin-right: 5px"> 
                        <i class="fa fa-plus" style="margin-right: 5px"></i>Grabar
                    </button>
                    <button @click="cleanGrabado()" type="button" class="btn btn-danger" ref="btnCancelar">
                        <i class="fa fa-trash-o" style="margin-right: 5px"></i>Cancel
                    </button>
                </div>
            </div>
          </div>
          <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
          <table class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>INDICADOR</th>
                <th>FECHA VIGENCIA</th>
                <th>DIAS</th>
                <th>ESTADO</th>
                <th>OBSERVACIÓN</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.Popup.TablaDetalle2" :key="key + 1">
                <td class="text-left">{{ item.TipoIndicador }}</td>
                <td class="text-center">{{ item.FechaIndicador }}</td>
                <td class="text-rigth">{{ item.diasFaltantes }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.estado === 'VIGENTE'">
                    <div
                      class="label label-default"
                      style=" color: white; background-color: #009900; padding: 3px 10px; border-radius: 5px;">
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'VENCIDO'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: #e02e2e;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'POR VENCER'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: rgb(246 137 54);
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'NO REGISTRADO'">
                    <div
                      class="label label-default"
                      style="
                        color: white;
                        background-color: #8d8d8d;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                </td>
                <td class="text-left">{{ item.Observacion }}</td>
                <td class="text-center" style="text-align: center">
                    <a href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      @click="openEdit(item)"
                    >
                      <span
                        class="fa fa-pencil"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)"
                    >
                      <span
                        class="fa fa-trash-o"
                        style="font-size: 1.6em"
                      ></span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>
</div>

  <div class="p-0" v-show="!vshow">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
     <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
     </button>
    </div>
    <div class="White-Box">
      <AddExcelIndi></AddExcelIndi>
     </div>
  </div>
</div>


</template>

<script>
import { APP } from "../../Content/base";
import AddExcelIndi from "../Indicadores/AddExcelIndi.vue"

    export default {
        name:"Indicadores",
        el: "#Indicadores",
        data() {
            return {
                filtro: {
                    Placa: "",
                    IndicadorTodos:true,
                    Indicador1:false,
                    Indicador2:false,
                    Indicador3:false,
                    Indicador4:false,
                    Indicador5:false,
                    Indicador6:false,
                    Indicador7:false,
                    Popup: {
                        VehiculoId:'',
                        EmpresaId:0,
                        Empresa:'',
                        Placa:'',
                        IdIndicadorXVehiculo:0,
                        Fecha:'',
                        DiaFaltante:0,
                        Estado:'',
                        Observacion:''
                    },
                },
                list: {
                    Empresa: [],
                    Estado: [
                    { id: 0, label: "NO REGISTRADO" },
                    { id: 1, label: "VIGENTE" },
                    { id: 2, label: "VENCIDO" },
                    { id: 3, label: "POR VENCER" },
                    ],
                    Popup: {
                        Indicador:[],
                        TablaDetalle2:[],
                    },
                    TablaDetalle: [],
                },
                vselect: {
                    Empresa: null,
                    Estado: null,
                    Popup: {
                        Indicador:null,
                    },
                },
                
                NUM_RESULTS: 10, // Numero de resultados por página
                pag: 1, // Página inicial
                vshow: true,
            };
        },
        async mounted() {
            let _this = this;

            await _this.getEmpresa();
            await _this.getTipoIndicador();
        },
        components: {AddExcelIndi},
        methods: {
            nextFocus: async function (ref, type, time = 100) {
            setTimeout(() => {
                switch (type) {
                case "input":
                    ref.focus();
                    break;
                case "money":
                    ref.$refs.money.focus();
                    break;
                case "radio":
                    ref.$refs.input.focus();
                    break;
                case "check":
                    ref.$refs.input.focus();
                    break;
                case "combo":
                    ref.$refs.search.focus();
                    break;
                case "btn":
                    ref.focus();
                    break;
                default:
                    ref();
                }
            }, time);
            },
            getEmpresa: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/ListEmpresa")
                .then((res) => {
                if (res.data.EsCorrecto) {
                    _this.list.Empresa = res.data.Valor;
                    _this.vselect.Empresa = _this.list.Empresa[0];
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            getTipoIndicador: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/netcore/ListTipoIndicador")
                .then((res) => {
                if (res.data.EsCorrecto) {
                    _this.list.Popup.Indicador = res.data.Valor;
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            geBusqueda: async function () {
            let _this = this;

            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            var request = {
                IdEmpresa: _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 1,
                Placa:  _this.filtro.Placa || "",
                Indicador1: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador1,
                Indicador2: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador2,
                Indicador3: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador3,
                Indicador4: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador4,
                Indicador5: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador5,
                Indicador6: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador6,
                Indicador7: _this.filtro.IndicadorTodos ? true : _this.filtro.Indicador7
            };

            await this.axios
                .post("api/indicadores/IndicadoresXVehiculo", request)
                .then((res) => {
                    if (res.data.EsCorrecto) {

                        _this.list.TablaDetalle = res.data.Valor;
                        if( _this.list.TablaDetalle.length >0){
                            _this.pag = 1
                          }

                    } else { APP.msg.error(this.$toast, res.data.Mensaje); }
                })
                .catch((error) => {
                      APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
                      localStorage.clear();
                      this.$store.state.isLogeado = false;
                      this.$router.push("/");
                });
            },
            getFormula: async function (_Fecha) {
            let _this = this;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

            await this.axios
                .get("api/revisionTecnica/getFormula/" + _Fecha)
                .then((res) => {
                if (res.data.EsCorrecto) {
                    var _Data = res.data.Valor;
                    if (_Data) {
                    _this.filtro.Popup.DiaFaltante = _Data.diasFaltantes;
                    _this.filtro.Popup.Estado = _Data.estado;
                    }
                } else {
                    APP.msg.error(this.$toast, res.data.Mensaje);
                }
                })
                .catch((error) => {
                APP.msg.error(
                    this.$toast,
                    "Token expirado Inicie session..!!" + error
                );
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
                });
            },
            verDetalle: async function(_item){
                let _this = this;
                   _this.filtro.Popup.VehiculoId = _item.VehiculoId;
                    _this.filtro.Popup.EmpresaId = _item.EmpresaId;
                    _this.filtro.Popup.Empresa= _item.Empresa;
                    _this.filtro.Popup.Placa= _item.Placa;

                    await _this.geBusquedaDetalle();
            },
            geBusquedaDetalle: async function () {
                    let _this = this;

                    this.axios.defaults.headers.common["Authorization"] =
                        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                    await this.axios
                        .get("api/indicadores/IndicadoresXVehiculoDetalle/" + _this.filtro.Popup.EmpresaId + '/' +_this.filtro.Popup.VehiculoId)
                        .then((res) => {
                            if (res.data.EsCorrecto) {
                                _this.list.Popup.TablaDetalle2 = res.data.Valor;
                            } else { APP.msg.error(this.$toast, res.data.Mensaje); }
                        })
                        .catch((error) => {
                            APP.msg.error(this.$toast, "Token expirado Inicie session..!!" + error);
                            localStorage.clear();
                            this.$store.state.isLogeado = false;
                            this.$router.push("/");
                        });
            },

            openEdit: async function (_item) {
            let _this = this;
                    setTimeout(async () => {
                        _this.cleanGrabado();
                        _this.filtro.Popup.IdIndicadorXVehiculo = _item.IdIndicadorXVehiculo;  

                        if(_item.FechaIndicador){
                                var sp =   _item.FechaIndicador.split('/');
                                var newValue = sp[2] + '-' + sp[1]+ '-' + sp[0];
                                _this.filtro.Popup.Fecha = newValue; 
                            }
                        _this.vselect.Popup.Indicador = _this.list.Popup.Indicador.find((o)=>{ return parseInt(o.Id) === parseInt(_item.IdIndicador)});
                        _this.filtro.Popup.Observacion = _item.Observacion;

                        this.nextFocus(this.$refs.refFecha, 'input', 200);
                    }, 300);
            },
            cleanGrabado: function(){
                let _this = this;
               _this.filtro.Popup.IdIndicadorXVehiculo=0;
               _this.filtro.Popup.Fecha='';
               _this.filtro.Popup.DiaFaltante=0;
               _this.filtro.Popup.Estado='';
               _this.filtro.Popup.Observacion='';
               _this.vselect.Popup.Indicador = null;

            },
            cleanField: async function(){
                let _this = this;
                 _this.filtro.Popup.VehiculoId='';
                 _this.filtro.Popup.EmpresaId=0;
                 _this.filtro.Popup.Empresa='';
                 _this.filtro.Popup.Placa='';
                 _this.filtro.Popup.IdIndicadorXVehiculo=0;
                 _this.filtro.Popup.Fecha='';
                 _this.filtro.Popup.DiaFaltante=0;
                 _this.filtro.Popup.Estado='';
                 _this.filtro.Popup.Observacion='';
                 _this.vselect.Popup.Indicador = null;
                 await _this.geBusqueda();
            },
            register: async function () {
               let _this = this;
               var validando = await _this.validarCampos();
               if(validando){
                      this.axios.defaults.headers.common["Authorization"] =
                          "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                      var usuario = JSON.parse(localStorage.getItem("Usuario"));

                      var request = {
                        "IdIndicadorXVehiculo": _this.filtro.Popup.IdIndicadorXVehiculo > 0 ? parseInt( _this.filtro.Popup.IdIndicadorXVehiculo) : 0,
                        "EmpresaId": _this.filtro.Popup.EmpresaId,
                        "VehiculoId": _this.filtro.Popup.VehiculoId,
                        "IdIndicador": _this.vselect.Popup.Indicador ? parseInt(_this.vselect.Popup.Indicador.Id) : 0,
                        "FechaIndicador": _this.filtro.Popup.Fecha,
                        "Observacion": _this.filtro.Popup.Observacion,
                        "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
                        "Tipo": _this.filtro.Popup.IdIndicadorXVehiculo > 0 ? '2' : '1'
                      };

                      await this.axios
                          .post("api/indicadores/InsertUpdate", request)
                          .then(async (res) => {
                          if (res.data.EsCorrecto) {
                          
                               if(res.data.Estado){
                                    APP.msg.success(this.$toast, "Se registro correctamente ..!");
                                    _this.cleanGrabado();
                                    await _this.geBusquedaDetalle();
                                } else{
                                    APP.msg.warning(this.$toast, res.data.Mensaje);
                                }
                          } else {
                              APP.msg.error(this.$toast, res.data.Mensaje);
                          }
                          })
                          .catch((error) => {
                              APP.msg.error( this.$toast,  "Token expirado Inicie session..!!" + error);
                              localStorage.clear();
                              this.$store.state.isLogeado = false;
                              this.$router.push("/");
                          });
                  }
            },
            Delete: async function (_item) {
                let _this = this;
                if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

                this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

                var request = {
                    "IdIndicadorXVehiculo": _item.IdIndicadorXVehiculo,
                    "EmpresaId":   _this.filtro.Popup.EmpresaId,
                    "VehiculoId":  _item.VehiculoId,
                };

                await this.axios
                    .post("api/indicadores/Delete", request)
                    .then(async (res) => {
                    if (res.data.EsCorrecto) {
                        APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                        await this.geBusquedaDetalle();
                    } else {
                        APP.msg.error(this.$toast, res.data.Mensaje);
                    }
                    })
                    .catch((error) => {
                        APP.msg.error(
                            this.$toast,
                            "Token expirado Inicie session..!!" + error
                        );
                        localStorage.clear();
                        this.$store.state.isLogeado = false;
                        this.$router.push("/");
                    });

            }

            },
            validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.vselect.Popup.Indicador === null && !(_this.vselect.Popup.Indicador && _this.vselect.Popup.Indicador.Id)){
                  APP.msg.warning( this.$toast,"Ingrese Tipo Indicador");
                  _this.nextFocus(this.$refs.refIndicadorP, 'combo', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.Fecha === ''){
                  APP.msg.warning( this.$toast,"Ingrese Fecha");
                  _this.nextFocus(this.$refs.refFecha, 'input', 200);
                  response = false;
                  return response;
                }
                
                return response;
            },  
            VerImporteMasivo: async function(){
              document.getElementById('IdLimpDtaIndicador').click();
                this.vshow = !this.vshow;
                if(this.vshow){
                  await this.geBusqueda();
               }
            }
        },
        watch: {
            "vselect.Empresa": async function (value, oldValue) {
              let _this = this;
              if (value != null && value != oldValue) {
                  await _this.geBusqueda();
              }
            },
            "filtro.IndicadorTodos": async function (newValue) {
              let _this = this;
               if(newValue){
                    _this.filtro.Indicador1=true;
                    _this.filtro.Indicador2=true;
                    _this.filtro.Indicador3=true;
                    _this.filtro.Indicador4=true;
                    _this.filtro.Indicador5=true;
                    _this.filtro.Indicador6=true;
                    _this.filtro.Indicador7=true;
                   
               }else{
                    _this.filtro.Indicador1=false;
                    _this.filtro.Indicador2=false;
                    _this.filtro.Indicador3=false;
                    _this.filtro.Indicador4=false;
                    _this.filtro.Indicador5=false;
                    _this.filtro.Indicador6=false;
                    _this.filtro.Indicador7=false;
               }
                await _this.geBusqueda();
            },
            "filtro.Indicador1": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador2": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador3": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador4": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador5": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador6": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Indicador7": async function(){
                if(!this.filtro.IndicadorTodos){
                  await this.geBusqueda();
                }
            },
            "filtro.Placa": async function(){

              if (!(await APP.Func.delayWhileIsSearching())) return;
               await this.geBusqueda();
            },
            "filtro.Popup.Fecha": async function (value) {
            let _this = this;
            if (value != null && value.length == 10) {
                await _this.getFormula(value);
            }
            },
            "vselect.Popup.Indicador": async function (newvalue, oldValue) {
               let _this = this;
                    if (newvalue && newvalue != oldValue) {
                        _this.nextFocus(_this.$refs.refFecha, 'input', 200)
                    }
            },
        },
    }
</script>

<style scoped>

</style>