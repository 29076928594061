<template>
    <a  href="javascript:void(0)"
         class="text-primary m-r-10"
         id="InfoOpenModalSUP"
         data-bs-toggle="modal"
         data-bs-target="#exampleModal5"
         style="display: none;"
         >
         <span class="fa fa-pencil" style="font-size: 1.6em"></span>
         </a>
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length == 0">
            <div class="drop-area"  id="dropAreaDrag">
                <i style="margin-top: 30px; color: #1fa71f !important;" class="fa fa-file-excel-o fa-4x"></i>
                <h2>Suelta aqui el archivo o seleccionalo</h2>
                <button @click="$refs.refInputLogo1.click()">Seleccionar archivo</button>
                <input type="file" @change="readExcel($event)" name="" id="input-file" ref="refInputLogo1" accept=".xlsx, .xls, .xlsm" hidden multiple style="visibility: hidden;" />
            </div>
            <div id="preview"></div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length > 0">
            <h4 style="text-align: center;  color: #61a239;">Operaciones Supervisor</h4>
          <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table">
            <thead>
              <tr>
                <th>NUMERO VIAJE</th>
                <th>CONDUCTOR</th>
                <th>PLACA</th>
                <th>SUPERVISOR</th>
                <th>FECHA GEN. VIAJE</th>
                <th style="background-color: #1c9825 !important;">OPERACION</th>
                <th style="background-color: #1c9825 !important;">RUTA</th>
                <th style="background-color: #1c9825 !important;">DESTINO</th>
                <th style="background-color: #1c9825 !important;">TIPO VIAJE</th>
                <th style="background-color: #1c9825 !important;">Fecha Entrega</th>
                <th style="background-color: #1c9825 !important;">Tipo REGISTRO</th>
                <th style="background-color: #1c9825 !important;">Registro GUIA RT</th>
                <th style="background-color: #1c9825 !important;">Fecha Carga CLIENTE</th>
                <th style="background-color: #1c9825 !important;">Guia Remision Cliente</th>
                <th style="background-color: #1c9825 !important;">Guia Remision Wari</th>
                <th style="background-color: #1c9825 !important;">Peso Bruto Mina TN</th>
                <th style="background-color: #1c9825 !important;">Peso Neto Mina TN</th>
                <th style="background-color: #1c9825 !important;">Peso Bruto Almacen TN</th>
                <th style="background-color: #1c9825 !important;">Precio Stand By US$</th>
                <th style="background-color: #1c9825 !important;">NUEVA SALIDA</th>
                <th style="background-color: #1c9825 !important;">OBSERVACIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key " >
                <td class="text-left">{{ item.NUMERO_VIAJE }}</td>
                <td class="text-left">{{ item.CONDUCTOR }}</td>
                <td class="text-left">{{ item.PLACA }}</td>
                <td class="text-left">{{ item.SUPERVISOR }}</td>
                <td class="text-left">{{ item.FECHA_GENERAVIAJE }}</td>
                <td class="text-left">{{ item.OPERACION }}</td>
                <td class="text-left">{{ item.RUTA }}</td>
                <td class="text-left">{{ item.DESTINO }}</td>
                <td class="text-left">{{ item.TIPO_VIAJE }}</td>
                <td class="text-left">{{ item.Fecha_Entrega}}</td>
                <td class="text-left">{{ item.Tipo_REGISTRO }}</td>
                <td class="text-left">{{ item.Registro_GUIA_RT }}</td>
                <td class="text-left">{{ item.Fecha_Carga_CLIENTE }}</td>
                <td class="text-left">{{ item.GuiaRemision_Cliente }}</td>
                <td class="text-left">{{ item.GuiaRemision_Wari }}</td>
                <td class="text-left">{{ item.PesoBruto_MinaTN }}</td>
                <td class="text-left">{{ item.PesoNeto_MinaTN }}</td>
                <td class="text-left">{{ item.PesoBruto_AlmacenTN }}</td>
                <td class="text-left">{{ item.PrecioStand_ByUS }}</td>
                <td class="text-left">{{ item.NUEVA_SALIDA }}</td>
                <td class="text-left">{{ item.OBSERVACIONES }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important">
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a  href="#" aria-label="Previous" v-show="pag != 1"  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px">
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px">
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important; text-align: right;">
          <button @click="limpiarData()"   class="btn btn-danger" style="margin-right: 3px !important" id="IdLimpDtaSup">
               <span style="margin-right: 5px"><i class="fa fa-close"></i></span>Limpiar Data
          </button>
          <button @click="register()"   class="btn btn-info" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-save"></i></span>Grabar Data
          </button>
        </div>
        </div>
      </div>
  
      <div
      class="modal fade"
      id="exampleModal5"
      tabindex="-1"
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel5"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel5">
              Observaciones Encontradas
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
              <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
            <table class=" table table-responsive table-hover table-bordered custom-table">
              <thead>
                <tr>
                  <th>FILA</th>
                  <th>OBSERVACIÓN</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in list.popUp.table" :key="key + 1">
                  <td class="text-left">{{item.fila}}</td>
                  <td class="text-left">{{item.errorMessage}}</td>
                </tr>
              </tbody>
            </table>
          </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="close" type="button" class="btn btn-danger" data-bs-dismiss="modal" id="closeModalPrueba">
              <i class="fa fa-close" style="margin-right: 5px"></i>Cerrar
            </button>
          </div>
        </div>
      </div>
      </div>
  
  </template>
  
  <script>
  import * as XLSX from 'xlsx'
  import moment from "moment";
  import { APP } from "../../Content/base";
  
  export default {
      name:"AddExcelSupervisor",
      el: "#AddExcelSupervisor",
      data() {
      return {
           filtro: {},
           list: {
             TablaDetalle:[],
             popUp:{
              table:[]
             }
           },
           vselect: {},
  
           NUM_RESULTS: 10, // Numero de resultados por página
           pag: 1, // Página inicial
        };
       },
   async mounted() {
      let _this = this;
      document.addEventListener("dragover", function (event) {
       event.preventDefault();
       document.getElementById('dropAreaDrag').classList.add('active');
      });
  
      document.addEventListener("dragleave", function (event) {
        if (event.target.className == "dropAreaDrag") {
         event.target.style.border = "";
        }
      });
  
      document.addEventListener("drop", async function (event) {
         event.preventDefault();
         await _this.readExcel(event, 1);
         document.getElementById('dropAreaDrag').classList.remove('active');
    
      });
      document.addEventListener("dropAreaDrag", function (event) {
         if (event.target.className == "droptarget") {
           event.target.style.border = "3px dotted red";
         }
      });
    },
    methods: {
      readExcel: async function (e,t) {
        var file = null;
           if(t == 1)
            file = e.dataTransfer.files[0];
           else
            file = e.target.files[0];
            //const file = e.target.files[0];
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = async (e) => {
                   const bufferArray = e.target.result;
                   const wb = XLSX.read(bufferArray, { type: "buffer" });
                   const wsname = wb.SheetNames[0];
                   const ws = wb.Sheets[wsname];
                   const data = XLSX.utils.sheet_to_json(ws);   
                  
                  // console.log(data);
                   await this.procesarDta(data);
  
                   document.getElementById('dropAreaDrag').classList.remove('active');
                   document.getElementById("input-file").value = "";
                  
                };
                fileReader.onerror = (error) => {
                    reject(error);
                };
            });
            promise.then((d) => {
                alert(d);
            });
      },
      procesarDta: async function(_data){
      let _this = this;

      //_this.list.TablaDetalle = _data;
  
      for (let i = 0; i < _data.length; i++) {
        _data[i].FECHA_GENERAVIAJE = await _this.ExcelDateToJSDate(_data[i].FECHA_GENERAVIAJE);
       
        //SUP
        _data[i].Fecha_Entrega = await _this.ExcelDateToJSDate(_data[i].Fecha_Entrega);
        _data[i].Fecha_Carga_CLIENTE = await _this.ExcelDateToJSDate(_data[i].Fecha_Carga_CLIENTE);
      }
  
      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
        var usuario = JSON.parse(localStorage.getItem("Usuario"));
  
        var request = {
              "IdEmpresa": 1,
              "Modulo": "SUPERVISOR",
        };
      
      request.Detalle = [];
      _data.forEach((_item) => {
        request.Detalle.push({
                      "NUMERO_VIAJE": String(_item.NUMERO_VIAJE),
                      "CONDUCTOR": String(_item.CONDUCTOR),
                      "PLACA":  String(_item.PLACA),
                      "SUPERVISOR":  String(_item.SUPERVISOR),
                      "FECHA_GENERAVIAJE":  String(_item.FECHA_GENERAVIAJE),
                      "KM_INICIAL": '',
                      "KM_FINAL": '',
                      "KM_SCANNER": '',
                      "GLN_SCANNER": 0,
                      "GLN_ESTACION": 0,
                      "SERIE_VALE_MANUAL": '',
                      "NUMERO_VALE_MANUAL": 0,
                      "FECHA_VALE_MANUAL": '',
                      "SERIE_VALE_JELAF": '',
                      "NUMERO_VALE_JELAF": 0,
                      "FECHA_VALE_JELAF": '',
                      "LITROS_UREA": 0,
                      "FECHAVALE_UREAJELAF": '',
                      "SERIEVALE_UREAJELAF": '',
                      "NUMEROVALE_UREAJELAF":  0,
                      "SERIEVALE_UREAMANUAL": '',
                      "NUMEROVALE_UREAMANUAL": 0,
                      "LUGAR_INICIO": '',
                      "FECHA_PARTIDA": '',
                      "LLEGADA_MINA": '',
                      "INICIO_CARGA":'',
                      "TERMINO_CARGA": '',
                      "SALIDAPUNTO_CARGA":'',
                      "LLEGADA_COCHERA":'',
                      "SALIDA_COCHERA": '',
                      "LLEGADA_ALMACEN": '',
                      "INICIO_DESCARGA": '',
                      "TERMINO_DESCARGA": '',
                      "SALIDAPUNTO_DESCARGA": '',
                      "FIN_TRANSITO": '',
                      "PUNTO_FINAL": '',
                      "OPERACION": String(_item.OPERACION),
                      "RUTA": String(_item.RUTA),
                      "DESTINO": String(_item.DESTINO),
                      "TIPO_VIAJE": String(_item.TIPO_VIAJE),
                      "Fecha_Entrega": String(_item.Fecha_Entrega),
                      "Tipo_REGISTRO": String(_item.Tipo_REGISTRO),
                      "Registro_GUIA_RT": String(_item.Registro_GUIA_RT),
                      "Fecha_Carga_CLIENTE": String(_item.Fecha_Carga_CLIENTE),
                      "GuiaRemision_Cliente": String(_item.GuiaRemision_Cliente),
                      "GuiaRemision_Wari": String(_item.GuiaRemision_Wari),
                      "PesoBruto_MinaTN": parseFloat(_item.PesoBruto_MinaTN),
                      "PesoNeto_MinaTN": parseFloat(_item.PesoNeto_MinaTN),
                      "PesoBruto_AlmacenTN": parseFloat(_item.PesoBruto_AlmacenTN),
                      "PrecioStand_ByUS": parseFloat(_item.PrecioStand_ByUS),
                      "NUEVA_SALIDA": parseFloat(_item.NUEVA_SALIDA),
                      "OBSERVACIONES": String(_item.OBSERVACIONES),
                      "USR_RegisterApoyo":  0,
                      "USR_RegisterGps": 0,
                      "USR_RegisterSuper": usuario ? parseInt(usuario.UsuarioId) : 0,
                    });
        });
      
      await this.axios
        .post("api/operaciones/admin/validateFormatoMasivo", request)
        .then(async (res) => {
          if (res.data.EsCorrecto) {
          
            if(res.data.Valor.length>0){
               _this.list.popUp.table = res.data.Valor;
               document.getElementById('InfoOpenModalSUP').click();
            }else{
              _this.list.TablaDetalle = _data;
            }
      
      
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        }); 
      },
      ExcelDateToJSDate: async function(date) {
       var newdate = new Date(Math.round((date - 25569)*86400*1000));
       var ss =  moment(newdate).add(1, 'days');
       return moment(ss,"YYYY-MM-DD").format('DD/MM/YYYY');
      },
      limpiarData: function(){
        let _this = this;
        _this.list.TablaDetalle = [];
      },
      register: async function () {
        let _this = this;
  
            this.axios.defaults.headers.common["Authorization"] =
              "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
  
            var usuario = JSON.parse(localStorage.getItem("Usuario"));
  
            var request = {
              "IdEmpresa": 1,
              "Modulo": "SUPERVISOR",
            };
  
            request.Detalle = [];
               _this.list.TablaDetalle.forEach((_item) => {
                    request.Detalle.push({
                      "NUMERO_VIAJE": String(_item.NUMERO_VIAJE),
                      "CONDUCTOR": String(_item.CONDUCTOR),
                      "PLACA":  String(_item.PLACA),
                      "SUPERVISOR":  String(_item.SUPERVISOR),
                      "FECHA_GENERAVIAJE":  String(_item.FECHA_GENERAVIAJE),
                      "KM_INICIAL": '',
                      "KM_FINAL": '',
                      "KM_SCANNER": '',
                      "GLN_SCANNER": 0,
                      "GLN_ESTACION": 0,
                      "SERIE_VALE_MANUAL": '',
                      "NUMERO_VALE_MANUAL": 0,
                      "FECHA_VALE_MANUAL": '',
                      "SERIE_VALE_JELAF": '',
                      "NUMERO_VALE_JELAF": 0,
                      "FECHA_VALE_JELAF": '',
                      "LITROS_UREA": 0,
                      "FECHAVALE_UREAJELAF": '',
                      "SERIEVALE_UREAJELAF": '',
                      "NUMEROVALE_UREAJELAF":  0,
                      "SERIEVALE_UREAMANUAL": '',
                      "NUMEROVALE_UREAMANUAL": 0,
                      "LUGAR_INICIO": '',
                      "FECHA_PARTIDA": '',
                      "LLEGADA_MINA": '',
                      "INICIO_CARGA":'',
                      "TERMINO_CARGA": '',
                      "SALIDAPUNTO_CARGA":'',
                      "LLEGADA_COCHERA":'',
                      "SALIDA_COCHERA": '',
                      "LLEGADA_ALMACEN": '',
                      "INICIO_DESCARGA": '',
                      "TERMINO_DESCARGA": '',
                      "SALIDAPUNTO_DESCARGA": '',
                      "FIN_TRANSITO": '',
                      "PUNTO_FINAL": '',
                      "OPERACION": String(_item.OPERACION),
                      "RUTA": String(_item.RUTA),
                      "DESTINO": String(_item.DESTINO),
                      "TIPO_VIAJE": String(_item.TIPO_VIAJE),
                      "Fecha_Entrega": String(_item.Fecha_Entrega),
                      "Tipo_REGISTRO": String(_item.Tipo_REGISTRO),
                      "Registro_GUIA_RT": String(_item.Registro_GUIA_RT),
                      "Fecha_Carga_CLIENTE": String(_item.Fecha_Carga_CLIENTE),
                      "GuiaRemision_Cliente": String(_item.GuiaRemision_Cliente),
                      "GuiaRemision_Wari": String(_item.GuiaRemision_Wari),
                      "PesoBruto_MinaTN": parseFloat(_item.PesoBruto_MinaTN),
                      "PesoNeto_MinaTN": parseFloat(_item.PesoNeto_MinaTN),
                      "PesoBruto_AlmacenTN": parseFloat(_item.PesoBruto_AlmacenTN),
                      "PrecioStand_ByUS": parseFloat(_item.PrecioStand_ByUS),
                      "NUEVA_SALIDA": parseFloat(_item.NUEVA_SALIDA),
                      "OBSERVACIONES": String(_item.OBSERVACIONES),
                      "USR_RegisterApoyo":  0,
                      "USR_RegisterGps": 0,
                      "USR_RegisterSuper": usuario ? parseInt(usuario.UsuarioId) : 0,
                    });
              });
  
            await this.axios
              .post("api/operaciones/admin/RegistroOperacionesComplete", request)
              .then(async (res) => {
                if (res.data.EsCorrecto) {
                
                   if(res.data.Estado)
                   {
                    APP.msg.success(this.$toast, res.data.Mensaje);
                    _this.list.TablaDetalle = [];
                   }
                   else
                   {
                    APP.msg.warning(this.$toast,  res.data.Mensaje);
                   }
                } else {
                  APP.msg.error(this.$toast, res.data.Mensaje);
                }
              })
              .catch((error) => {
                APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
                localStorage.clear();
                this.$store.state.isLogeado = false;
                this.$router.push("/");
              });
        
      },
      close: function(){
        let _this = this;
        _this.list.popUp.table = [];
      },
  
  
    },
    }
  </script>
  
  <style scoped>
  
  </style>