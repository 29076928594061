<template>
  <!-- <div class="Container">
    <div class="White-Box"> -->
      <a  href="javascript:void(0)"
       class="text-primary m-r-10"
       id="InfoOpenModal"
       data-bs-toggle="modal"
       data-bs-target="#exampleModalXX"
       style="display: none;"
       >
       <span class="fa fa-pencil" style="font-size: 1.6em"></span>
       </a>
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length == 0">
            <div class="drop-area"  id="dropAreaDrag">
                <i style="margin-top: 30px; color: #1fa71f !important;" class="fa fa-file-excel-o fa-4x"></i>
                <h2>Suelta aqui el archivo o seleccionalo</h2>
                <button @click="$refs.refInputLogo1.click()">Seleccionar archivo</button>
                <input type="file" @change="readExcel($event)" name="" id="input-file" ref="refInputLogo1" accept=".xlsx, .xls, .xlsm" hidden multiple style="visibility: hidden;" />
            </div>
            <div id="preview"></div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" v-show="list.TablaDetalle.length > 0">
          <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table">
            <thead>
              <tr>
                <th>PLACA</th>
                <th>FECHA INSPECCIÓN</th>
                <th>FECHA PROX INSPECCIÓN</th>
                <th>PLANTA REVISION TECNICA</th>
                <th>CERTIFICADO</th>
                <th>INFORME</th>
                <th>OBSERVACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <!-- <paginate ref="paginator" name="items" :list="list.TablaDetalle" :per="per"> -->
              <tr v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key " >
                <td class="text-left">{{ item.PLACA }}</td>
                <td class="text-left">{{ item.Fecha_Inspeccion }}</td>
                <td class="text-left">{{ item.FechaProx_Inspeccion }}</td>
                <td class="text-left">{{ item.Planta_Rev_tecnica }}</td>
                <td class="text-left">{{ item.Nro_Certificado }}</td>
                <td class="text-left">{{ item.Nro_Informe }}</td>
                <td class="text-left">{{ item.Observacion }}</td>
              </tr>
              <!-- </paginate> -->
            </tbody>
          </table>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important">
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a  href="#" aria-label="Previous" v-show="pag != 1"  @click.prevent="pag -= 1" style="padding: 0px 0px 0px 0px">
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767">
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a href="#" aria-label="Next" v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1" @click.prevent="pag += 1" style="padding: 0px 10px 0px 5px">
                  <span aria-hidden="true">
                    <button class="btn btn-info" style="height: 25px; line-height: 10px; color: #344767" >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="margin-top: 10px !important; text-align: right;">
          <button @click="limpiarData()"   class="btn btn-danger" style="margin-right: 3px !important" id="IdLimpiarData">
               <span style="margin-right: 5px"><i class="fa fa-close"></i></span>Limpiar Data
          </button>
          <button  @click="register()"  class="btn btn-info" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-save"></i></span>Grabar Data
          </button>
        </div>
        </div>
      </div>
       <!-- </div>
  </div> -->



  <div
    class="modal fade"
    id="exampleModalXX"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabelXX"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabelXX">
            Observaciones Encontradas
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table">
            <thead>
              <tr>
                <th>FILA</th>
                <th>OBSERVACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in list.popUp.table" :key="key + 1">
                <td class="text-left">{{item.fila}}</td>
                <td class="text-left">{{item.errorMessage}}</td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="close" type="button" class="btn btn-danger" data-bs-dismiss="modal" id="closeModalPrueba">
            <i class="fa fa-close" style="margin-right: 5px"></i>Cerrar
          </button>
        </div>
      </div>
    </div>
    </div>


</template>


<script>
import * as XLSX from 'xlsx'
import moment from "moment";
import { APP } from "../../Content/base";

export default {
  name: "appVueAddRevisionTecnica",
  el: "#appVueAddRevisionTecnica",
  data() {
    return {
      filtro: {},
      list: {
        TablaDetalle:[],
        popUp:{
          table:[],
        }
      },
      vselect: {},

      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
    };
  },
  components: {},
  async mounted() {
    let _this = this;
    document.addEventListener("dragover", function (event) {
     event.preventDefault();
     document.getElementById('dropAreaDrag').classList.add('active');
    });

    document.addEventListener("dragleave", function (event) {
     if (event.target.className == "dropAreaDrag") {
         event.target.style.border = "";
     }
    });

    document.addEventListener("drop", async function (event) {
     event.preventDefault();
         await _this.readExcel(event, 1);
         document.getElementById('dropAreaDrag').classList.remove('active');
    
   });
    document.addEventListener("dropAreaDrag", function (event) {
     if (event.target.className == "droptarget") {
         event.target.style.border = "3px dotted red";
     }
   });
  },
  methods: {
    openFileManager2: function () {
        document.getElementById('file-upload-2').click();
    },
    readExcel: async function (e,t) {
        var file = null;
           if(t == 1)
            file = e.dataTransfer.files[0];
           else
            file = e.target.files[0];
            //const file = e.target.files[0];
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = async (e) => {
                   const bufferArray = e.target.result;
                   const wb = XLSX.read(bufferArray, { type: "buffer" });
                   const wsname = wb.SheetNames[0];
                   const ws = wb.Sheets[wsname];
                   const data = XLSX.utils.sheet_to_json(ws);   
                  
                  // console.log(data);
                  var valida = await this.ValidarCamposExcel(data);
                  if(valida){
                   await this.procesarDta(data);
                   document.getElementById('dropAreaDrag').classList.remove('active');
                   document.getElementById("input-file").value = "";
                  }else{
                    document.getElementById('dropAreaDrag').classList.remove('active');
                   document.getElementById("input-file").value = "";
                  }
                };
                fileReader.onerror = (error) => {
                    reject(error);
                };
            });
            promise.then((d) => {
                alert(d);
            });
    },
    ValidarCamposExcel: async function(_data){
        var response = true;
        var newDta = _data[0];

        if(!newDta.PLACA){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna Placa");
          response = false;
          return response;
        }
        if(!newDta.Fecha_Inspeccion){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna Fecha_Inspeccion ");
          response = false;
          return response;
        }
        if(!newDta.FechaProx_Inspeccion){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna FechaProx_Inspeccion");
          response = false;
          return response;
        }
        if(!newDta.Planta_Rev_tecnica){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna Planta_Rev_tecnica");
          response = false;
          return response;
        }
        if(!newDta.Nro_Certificado){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna Nro_Certificado");
          response = false;
          return response;
        }
        if(!newDta.Nro_Informe){
          APP.msg.warning(this.$toast,"No existe o no contiene datos la comlumna Nro_Informe");
          response = false;
          return response;
        }
 
        for (let i = 0; i < _data.length; i++) {
            if(_data[i].PLACA === "" || _data[i].PLACA == null ){
              APP.msg.warning(this.$toast,"No existe placa en la fila" + (i+1));
              response = false;
              return response;
            }
            if(_data[i].Fecha_Inspeccion == null ){
              APP.msg.warning(this.$toast,"No existe Fecha_Inspeccion en la fila" + (i+1));
              response = false;
              return response;
            }
            if(_data[i].FechaProx_Inspeccion == null ){
              APP.msg.warning(this.$toast,"No existe FechaProx_Inspeccion en la fila" + (i+1));
              response = false;
              return response;
            }
            if(_data[i].Nro_Certificado === "" || _data[i].Nro_Certificado == null ){
              APP.msg.warning(this.$toast,"No existe Nro_Certificado en la fila" + (i+1));
              response = false;
              return response;
            }
            if(_data[i].Nro_Informe === "" || _data[i].Nro_Informe == null ){
              APP.msg.warning(this.$toast,"No existe Nro_Informe en la fila" + (i+1));
              response = false;
              return response;
            }
        }
        return response;
    },
    procesarDta: async function(_data){
      let _this = this;

      for (let i = 0; i < _data.length; i++) {
        _data[i].Fecha_Inspeccion = await _this.ExcelDateToJSDate(_data[i].Fecha_Inspeccion);
        _data[i].FechaProx_Inspeccion = await _this.ExcelDateToJSDate(_data[i].FechaProx_Inspeccion);
      }

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
      var usuario = JSON.parse(localStorage.getItem("Usuario"));

    var request = {
      "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
    };
    
    request.Detalle = [];
    _data.forEach((_item, key) => {
            request.Detalle.push({
                "Item": key+1,
                "PLACA": String(_item.PLACA),
                "Fecha_Inspeccion": String(_item.Fecha_Inspeccion),
                "FechaProx_Inspeccion": String(_item.FechaProx_Inspeccion),
                "Planta_Rev_tecnica":String(_item.Planta_Rev_tecnica),
                "Nro_Certificado":String( _item.Nro_Certificado),
                "Nro_Informe": String(_item.Nro_Informe),
                "Observacion":String(_item.Observacion || ''),
            });
      });
    
    await this.axios
      .post("api/revisionTecnica/validateFormatoMasivo", request)
      .then(async (res) => {
        if (res.data.EsCorrecto) {
        
          if(res.data.Valor.length>0){
             _this.list.popUp.table = res.data.Valor;
             document.getElementById('InfoOpenModal').click();
          }else{

            _this.list.TablaDetalle = _data;

          }
    
    
        } else {
          APP.msg.error(this.$toast, res.data.Mensaje);
        }
      })
      .catch((error) => {
        APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
        localStorage.clear();
        this.$store.state.isLogeado = false;
        this.$router.push("/");
      }); 
    },
    ExcelDateToJSDate: async function(date) {
       var newdate = new Date(Math.round((date - 25569)*86400*1000));
       var ss =  moment(newdate).add(1, 'days');
       return moment(ss,"YYYY-MM-DD").format('DD/MM/YYYY');
    },
    limpiarData: function(){
      let _this = this;
      _this.list.TablaDetalle = [];
    },
    register: async function () {
      let _this = this;

          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
          };

          request.Detalle = [];
             _this.list.TablaDetalle.forEach((_item) => {
                  request.Detalle.push({
                      "PLACA": String(_item.PLACA),
                      "Fecha_Inspeccion": String(_item.Fecha_Inspeccion),
                      "FechaProx_Inspeccion": String(_item.FechaProx_Inspeccion),
                      "Planta_Rev_tecnica":String(_item.Planta_Rev_tecnica),
                      "Nro_Certificado":String( _item.Nro_Certificado),
                      "Nro_Informe": String(_item.Nro_Informe),
                      "Observacion":String(_item.Observacion || ''),
                  });
            });

          await this.axios
            .post("api/revisionTecnica/InsertMasivoRevisionTecnica", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                 if(res.data.Estado)
                 {
                  APP.msg.success(this.$toast, res.data.Mensaje);
                  _this.list.TablaDetalle = [];
                 }
                 else
                 {
                  APP.msg.warning(this.$toast,  res.data.Mensaje);
                 }
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      
    },
    close: function(){
      let _this = this;
      _this.list.popUp.table = [];
    },
  },
};
</script>

<style scoped>
</style>