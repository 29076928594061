<template>

     <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0 m-b-10">
        <div class="col-md-8 col-sm-8 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Descripción Configuración</label>
          <div class="">
            <input v-model="filtro.DescripcionConfig"
                   name="DescripcionConfig"
                   class="form-control input-sm"
                   placeholder=""
                   ref="refDescripcionConfig"
                   autocomplete="off"/>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">Usuario Registro</label>
          <div class="">
            <input v-model="filtro.Usuario"
                   name="Usuario"
                   class="form-control input-sm"
                   placeholder=""
                   ref="refUsuario"
                   autocomplete="off"
                   disabled/>
          </div>
        </div>
     </div>
     <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0" style="margin-bottom: 20px;">
        <div class="col-md-7 col-sm-7 col-xs-12 p-0">
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <label class="control-label label-sm" style="color: #0d6efd;font-size: 13px;font-weight: 600;">Agregar Notificaciones</label>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                    <button @click="OpenPopup()"  class="btn btn-primary" style="width: 100%;" data-bs-toggle="modal" data-bs-target="#exampleModalNot">
                      <span style="margin-right: 5px"><i class="fa fa-plus"></i></span>Agregar y/o Modificar Notificaciones
                    </button>
                </div>
                <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
                   <table class=" table table-responsive table-hover table-bordered custom-table ">
                     <thead>
                      <tr>
                        <th>Notificación</th>
                        <th>Alertar</th>
                        <th>Acciones</th>
                      </tr>
                     </thead>
                    <tbody>
                       <tr 
                        v-for="(item, key) in list.TablaDetalleNotificacion" :key="key + 1">
                         <td class="text-left">{{ item.Descripcion }}</td>
                         <td class="text-center" style="width: 15%;">{{ item.CantidadAlerta }}</td>
                         <td class="text-center" style="text-align: center; width: 10%;">
                             <a href="javascript:void(0)"
                               title="Ver Documento"
                               class="text-danger m-r-10"
                               id="Info"
                               style="color: #178719"
                               @click="deleteNotificacion(item.Id)"
                             > <span class="fa fa-trash-o" style="font-size: 1.6em" ></span></a>
                         </td>
                       </tr>
                    </tbody>
                   </table>
                </div>
            </div>
        </div>
        <div class="offset-md-1  col-md-4 col-sm-4 col-xs-12 p-0">
            <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                <label class="control-label label-sm" style="color: #0d6efd;font-size: 13px;font-weight: 600;">Agregar Empresas</label>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                   <div class="col-md-10 col-sm-10 col-xs-12 separate-in-form">
                     <v-select   v-model="vselect.Empresa"
                                 name="Empresa"
                                 :clearable="true"
                                 class="v-select-form"
                                 label="Descripcion"
                                 placeholder="--Seleccione empresa--"
                                 :options="list.Empresa"
                                 ref="refEmpresa" >
                     </v-select>
                   </div>
                   <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                    <a href="javascript:void(0)"
                      class="text-success m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      @click="AgregarEmpresa(item)"><span class="fa fa-plus-square" style="font-size: 2.5em" ></span>
                      </a>
                   </div>
                </div>
                <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
                   <table class=" table table-responsive table-hover table-bordered custom-table ">
                     <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>ACCIONES</th>
                      </tr>
                     </thead>
                    <tbody>
                       <tr 
                        v-for="(item, key) in list.TablaDetalleEmpresa" :key="key + 1">
                         <td class="text-left">{{item.Ruc}} - {{item.Descripcion}}</td>
                         <td class="text-center" style="text-align: center; width: 10%;">
                             <a href="javascript:void(0)"
                               title="Ver Documento"
                               class="text-danger m-r-10"
                               id="Info"
                               style="color: #178719"
                               @click="deleteEmpresa(item.Id)"
                             > <span class="fa fa-trash-o" style="font-size: 1.6em" ></span></a>
                         </td>
                       </tr>
                    </tbody>
                   </table>
                </div>
            </div>
        </div>
     </div>
     <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0 m-b-10">
        <label class="control-label label-sm" style="color: #0d6efd;font-size: 13px;font-weight: 600;">Agregar Responsables</label>
                <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
                   <div class="form-div col-md-7 col-sm-7 col-xs-12 p-0">
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                       <label class="control-label label-sm">Tipo Doc</label>
                         <div class="">
                            <v-select  v-model="vselect.TipoDoc"
                                       name="TipoDoc"
                                       :clearable="true"
                                       class="v-select-form"
                                       label="Descripcion"
                                       placeholder="--Seleccione--"
                                       @keydown.enter.prevent="nextFocus($refs.refDocumento, 'input', 300)"
                                       :options="list.TipoDoc"
                                       ref="refTipoDoc" >
                            </v-select>
                         </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                      <div class="col-md-12">
                       <label class="control-label label-sm">Documento</label>
                       <a href="javascript:void(0)"
                               class="text-danger m-r-10 pull-right"
                               id="Info"
                               @click="BuscarReniec()"
                               style="background: #0d6efd;padding: 3px 6px;border-radius: 5px; color: white !important;"
                              ref="refDocumento"><span class="fa fa-refresh" style="font-size: 1em" ></span>
                            </a>
                      </div>
                      <div class="col-md-12">
                         <input v-model="filtro.Documento"
                                name="Documento"
                                class="form-control input-sm"
                                placeholder=""
                                @keyup.enter="BuscarReniec()"
                                ref="refDocumento"
                                autocomplete="off"/>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
                       <label class="control-label label-sm">Apellidos y Nombres</label>
                         <div class="">
                            <input v-model="filtro.ApellidoNombre"
                                   name="ApellidoNombre"
                                   class="form-control input-sm"
                                   placeholder=""
                                   @keyup.enter="nextFocus($refs.refEmail, 'input', 200)"
                                   ref="refApellidoNombre"
                                   autocomplete="off"/>
                         </div>
                    </div>
                   </div>
                   <div class="form-div col-md-5 col-sm-5 col-xs-12 p-0">
                    <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
                       <label class="control-label label-sm">Email</label>
                         <div class="">
                            <input v-model="filtro.Email"
                                   name="Email"
                                   class="form-control input-sm"
                                   placeholder=""
                                   ref="refEmail"
                                   @keyup.enter="nextFocus($refs.refCelular, 'input', 200)"
                                   autocomplete="off"/>
                         </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                       <label class="control-label label-sm">Celular</label>
                         <div class="">
                            <input v-model="filtro.Celular"
                                   name="Celular"
                                   class="form-control input-sm"
                                   placeholder=""
                                   ref="refCelular"
                                   maxlength="9"
                                   @keyup.enter="nextFocus($refs.refCargo, 'input', 200)"
                                   autocomplete="off"/>
                         </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12 separate-in-form">
                       <label class="control-label label-sm">Cargo</label>
                         <div class="">
                            <input v-model="filtro.Cargo"
                                   name="Cargo"
                                   class="form-control input-sm"
                                   placeholder=""
                                   ref="refCargo"
                                   @keyup.enter="GrabarDatosResponsable()"
                                   autocomplete="off"/>
                         </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
                        <label class="control-label label-sm">&nbsp;</label>
                         <div class="">
                            <a href="javascript:void(0)"
                               title="Ver Documento"
                               class="text-success m-r-10"
                               id="Info"
                               style="color: #178719; margin-right: 5px !important"
                               @click="GrabarDatosResponsable()"
                             ><span class="fa fa-plus-square" style="font-size: 2.5em" ></span>
                            </a>
                            <a href="javascript:void(0)"
                               title="Cancelar"
                               class="text-danger m-r-10"
                               id="Info"
                               style="color: #178719; margin-right: 5px !important"
                               @click="CancelarResponsable()"
                             ><span class="fa fa-close" style="font-size: 2.5em" ></span>
                            </a>
                        </div>
                    </div>
                   </div>
                </div>
                <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
                   <table class=" table table-responsive table-hover table-bordered custom-table ">
                     <thead>
                      <tr>
                        <th>Tipo Doc</th>
                        <th>Documento</th>
                        <th>Apellidos y Nombres</th>
                        <th>Email</th>
                        <th>Telefono</th>
                        <th>Cargo</th>
                        <th>Acciones</th>
                      </tr>
                     </thead>
                    <tbody>
                       <tr 
                        v-for="(item, key) in list.TablaDetalleResponsable" :key="key + 1">
                         <td class="text-left">{{ item.TipoDocDescripcion }}</td>
                         <td class="text-left">{{ item.NumDoc }}</td>
                         <td class="text-left">{{ item.ApellidoNombre }}</td>
                         <td class="text-left">{{ item.Email }}</td>
                         <td class="text-left">{{ item.Ceular }}</td>
                         <td class="text-left">{{ item.Cargo }}</td>
                         <td class="text-center" style="text-align: center">
                             <a href="javascript:void(0)"
                               title="Editar"
                               class="text-primary m-r-10"
                               id="Info"
                               style="color: #178719; margin-right: 5px !important"
                               @click="EditResponsable(item)"
                             ><span class="fa fa-pencil" style="font-size: 1.6em" ></span>
                            </a>
                             <a
                               href="javascript:void(0)"
                               title="Eliminar"
                               class="text-danger m-r-10"
                               id="Info"
                               style="color: #178719"
                               @click="EliminaResponsable(item)"
                             > <span class="fa fa-trash-o" style="font-size: 1.6em" ></span></a>
                         </td>
                       </tr>
                    </tbody>
                   </table>
                </div>

     </div>
     <div class="col-md-12 col-sm-12 col-xs-12 p-0 m-b-10" style=" text-align: right;">
      <button @click="Grabar()" class="btn btn-primary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-save"></i></span
        >Grabar
      </button>
     </div>

 <!-- Modal -->
 <div
    class="modal fade"
    id="exampleModalNot"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalNotLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalNotLabel">
            Notificación
          </h5>
          <button 
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="btnCloseP"
          ></button>
        </div>
        <div class="modal-body" style="height: 470px; overflow-y: auto;">
          <div class=" col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-t-10 m-b-10 paginate-padding " style="overflow-x: auto; margin-top:10px">
          <table class=" table table-responsive table-hover table-bordered custom-table ">
            <thead>
              <tr>
                <th>NOTIFICACIÓN</th>
                <th>ALERTA</th>
                <th class="text-rigth" style="width: 10%;">
                    <div style="text-align: center;">
                        <input v-model="checkAll"
                               type="checkbox"/>
                    </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr 
               v-for="(item, key) in list.Popup.TablaDetalle" :key="key + 1">
                <td class="text-left">{{ item.Descripcion }}</td>
                <td class="text-center" style="width: 20%;">
                    <div>
                        <input v-model="item.CantidadAlerta"
                               class="form-control input-sm"
                               autocomplete="off"
                               type="number"
                               v-mask="'####'"
                               min="0"
                               style="text-align: right;"
                               :disabled="!item.Selected"/>
                    </div>
                </td>
                <td class="text-rigth" style="width: 10%;">
                    <div style="text-align: center;">
                        <input v-model="item.Selected"
                               type="checkbox"/>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-0" style="padding: 5px !important; background-color: white; text-align: right;">
            <button @click="GrabarDataPopUp()" class="btn btn-primary" style="margin-right: 3px !important">
              <span style="margin-right: 5px"><i class="fa fa-save"></i></span>Grabar
            </button>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';
import { APP } from "../../Content/base";



export default {
  name:"appVueAddNotificacionAlerta",
  el: "#appVueAddNotificacionAlerta",
  data() {
    return {
      filtro: {
        IdConfig:0,
        Usuario:'',
        Documento:'',
        ApellidoNombre:'',
        Email:'',
        Celular:'',
        Cargo:'',
        DescripcionConfig:'',
      },
      list: {
        Empresa:[],
        TipoDoc:[
           {Id: 0, Descripcion: 'OTROS'},
           {Id: 1, Descripcion: 'DNI'},
           {Id: 4, Descripcion: 'CARNET EXTRANJERIA'},
           {Id: 7, Descripcion: 'PASAPORTE'}
        ],
        TablaDetalleNotificacion:[],
        TablaDetalleEmpresa:[],
        TablaDetalleResponsable:[],
        Popup:{
            TablaDetalle:[]
        }
      },
      vselect: {
        TipoDoc: null,
        Empresa: null,
      },
      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
      Editando: false,
    };
  },
  async mounted() {
   let _this = this;

   var usuario = JSON.parse(localStorage.getItem("Usuario"));
   _this.filtro.Usuario = usuario.Usuario;

   await _this.getNotificacion();
   await _this.getEmpresa();
  },
  components: {},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getEmpresa: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Empresa = res.data.Valor;
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    getNotificacion: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListNotificacion")
        .then((res) => {
          if (res.data.EsCorrecto) {

            _this.list.Popup.TablaDetalle = _.cloneDeep(res.data.Valor).map(x => { return { ...x, CantidadAlerta: 7, Selected: false }; });

          } else { APP.msg.error(this.$toast, res.data.Mensaje); }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    GrabarDataPopUp: async function(){
       let _this = this;

       var _item = _.filter(_this.list.Popup.TablaDetalle, ['Selected', true]);
       if(_item.length > 0){
        _this.list.TablaDetalleNotificacion = [];

          for (var i = 0; i < _item.length; i++) {
            _this.list.TablaDetalleNotificacion.push({
              Idkey: 0,
              IdConfig: 0,
              CantidadAlerta:_item[i].CantidadAlerta,
              Descripcion:_item[i].Descripcion,
              Id:_item[i].Id,
            });
         }

       }else{
        _this.list.TablaDetalleNotificacion = [];
       }

       document.getElementById('btnCloseP').click();
    },
    OpenPopup: async function(){
        let _this = this;

        if(_this.list.TablaDetalleNotificacion.length > 0){
          for (var i = 0; i < _this.list.TablaDetalleNotificacion.length; i++) {
            for (var x = 0; x < _this.list.Popup.TablaDetalle.length; x++) {
             if(_this.list.TablaDetalleNotificacion[i].Id == _this.list.Popup.TablaDetalle[x].Id  ){
                _this.list.Popup.TablaDetalle[x].Selected = true;
              }
             }
          }
        }
        else{
          for (var e = 0; e < _this.list.Popup.TablaDetalle.length; e++) {
            _this.list.Popup.TablaDetalle[e].Selected = false;
          }
        }
    },
    AgregarEmpresa: async function(){
      let _this = this;

      if(_this.vselect.Empresa != null){

      var index = _.findIndex(_this.list.TablaDetalleEmpresa, function (o) { return o.Id == _this.vselect.Empresa.Id; });
       if(index === -1){
            _this.list.TablaDetalleEmpresa.push({
              Idkey: 0,
              IdConfig: 0,
              Descripcion:_this.vselect.Empresa.Descripcion, 
              Ruc:_this.vselect.Empresa.Ruc, 
              Id:_this.vselect.Empresa.Id, 
            });

            _this.vselect.Empresa = null;
       }else{
        APP.msg.warning(this.$toast, "Empresa ya se encuentra en la lista");
       }

      }else{
        APP.msg.warning(this.$toast, "Seleccione empresa");
      }
    },
    deleteEmpresa: async function(_Id){
      let _this = this;
    if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){
      var index = _.findIndex(_this.list.TablaDetalleEmpresa, function (o) { return o.Id == _Id; });
      if(index != -1){
        _this.list.TablaDetalleEmpresa.splice(index,1);
      }
     }
    },
    deleteNotificacion: async function(_Id){
      let _this = this;
    if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){
      var index = _.findIndex(_this.list.TablaDetalleNotificacion, function (o) { return o.Id == _Id; });
      if(index != -1){
        _this.list.TablaDetalleNotificacion.splice(index,1);
      }
    }

    },
    GrabarDatosResponsable: async function(){
      let _this = this;
      
      var valida = await _this.ValidarCampos();
      if(valida){

      var index = _.findIndex(_this.list.TablaDetalleResponsable, function (o) { return (o.TipoDoc == _this.vselect.TipoDoc.Id &&  o.NumDoc == _this.filtro.Documento)});
       if(index === -1){
            _this.list.TablaDetalleResponsable.push({
              Idkey: 0,
              IdConfig: 0,
              TipoDoc: _this.vselect.TipoDoc.Id, 
              TipoDocDescripcion: _this.vselect.TipoDoc.Descripcion, 
              NumDoc:_this.filtro.Documento, 
              ApellidoNombre:_this.filtro.ApellidoNombre,
              Email:_this.filtro.Email, 
              Ceular:_this.filtro.Celular, 
              Cargo:_this.filtro.Cargo, 
            });
       } else {

        _this.list.TablaDetalleResponsable[index].ApellidoNombre = _this.filtro.ApellidoNombre;
        _this.list.TablaDetalleResponsable[index].Email = _this.filtro.Email;
        _this.list.TablaDetalleResponsable[index].Ceular = _this.filtro.Celular;
        _this.list.TablaDetalleResponsable[index].Cargo = _this.filtro.Cargo;
       }

       _this.vselect.TipoDoc = null;
       _this.filtro.Documento = "";
       _this.filtro.ApellidoNombre = "";
       _this.filtro.Email = "";
       _this.filtro.Celular = "";
       _this.filtro.Cargo = "";

      }
    },
    EditResponsable: function(_item){
      let _this = this;

       _this.vselect.TipoDoc = _.find(_this.list.TipoDoc, (o)=>{return parseInt(o.Id) === parseInt(_item.TipoDoc)});
       _this.filtro.Documento = _item.NumDoc;
       _this.filtro.ApellidoNombre =  _item.ApellidoNombre;
       _this.filtro.Email =  _item.Email;
       _this.filtro.Celular =  _item.Ceular;
       _this.filtro.Cargo =  _item.Cargo;

    },
    EliminaResponsable: async function(_item){
      let _this = this;
     if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){
      var index = _.findIndex(_this.list.TablaDetalleResponsable, function (o) { return o.TipoDoc == _item.TipoDoc && o.NumDoc == _item.NumDoc });
      if(index != -1){
        _this.list.TablaDetalleResponsable.splice(index,1);
      }
     }
    },
    CancelarResponsable: function(){
      let _this = this;

      _this.vselect.TipoDoc = null;
       _this.filtro.Documento = "";
       _this.filtro.ApellidoNombre = "";
       _this.filtro.Email = "";
       _this.filtro.Celular = "";
       _this.filtro.Cargo = "";
    },
    ValidarCampos: async function(){
      let _this = this;
      var response = true;
      
      if(_this.vselect.TipoDoc === null && !(_this.vselect.TipoDoc && _this.vselect.TipoDoc.Id)){
        _this.nextFocus(_this.$refs.refTipoDoc, 'combo', 300);
        APP.msg.warning(this.$toast, "Ingrese Tipo Documento");
        response = false;
        return response;
      }
      if(_this.filtro.Documento === ''){
        _this.nextFocus(_this.$refs.refDocumento, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Documento");
        response = false;
        return response;
      }
      if(_this.filtro.ApellidoNombre === ''){
        _this.nextFocus(_this.$refs.refApellidoNombre, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Apellido Nombre");
        response = false;
        return response;
      }
      if(_this.filtro.Email === ''){
        _this.nextFocus(_this.$refs.refEmail, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Email");
        response = false;
        return response;
      }
      if(_this.filtro.Celular === ''){
        _this.nextFocus(_this.$refs.refCelular, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Celular");
        response = false;
        return response;
      }
      if(_this.filtro.Cargo === ''){
        _this.nextFocus(_this.$refs.refCargo, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Cargo");
        response = false;
        return response;
      }

      return response;
    },
    Edita: async function(_Item){
       let _this = this;
       
       this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));
       
       await this.axios
        .get("api/configuracion/ListDetallesConfiguraciones?IdConfig="+ _Item.IdConfig)
        .then((res) => {
          if (res.data.EsCorrecto) {
                _this.filtro.IdConfig = _Item.IdConfig;
                _this.filtro.DescripcionConfig = _Item.Descripcion;
                _this.list.TablaDetalleEmpresa = _.cloneDeep(res.data.Valor.DetalleEmpresa);
                _this.list.TablaDetalleNotificacion = _.cloneDeep(res.data.Valor.DetalleNotificacion);
                _this.list.TablaDetalleResponsable = _.cloneDeep(res.data.Valor.DetalleResponsable);
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });




      //  await this.axios.get("api/configuracion/ListDetallesConfiguraciones?IdConfig=" + _Item.IdConfig)
      //  .then(async (res) => {
      //      if (res.data.EsCorrecto) {
      //         if(res.data.Valor){

      //           _this.filtro.IdConfig = _Item.IdConfig;
      //           _this.filtro.DescripcionConfig = _Item.Descripcion;
      //           _this.list.TablaDetalleEmpresa = _.cloneDeep(res.data.Valor.DetalleEmpresa);
      //           _this.list.TablaDetalleNotificacion = _.cloneDeep(res.data.Valor.DetalleNotificacion);
      //           _this.list.TablaDetalleResponsable = _.cloneDeep(res.data.Valor.DetalleResponsable);
      //         }
      //      } else { APP.msg.error(this.$toast, res.data.Mensaje);}
      //      }).catch((error) => {
      //         APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error );
      //         localStorage.clear();
      //         this.$store.state.isLogeado = false;
      //         this.$router.push("/");
      //       });
    },
    Grabar: async function(){
      let _this = this;

      var validando = await _this.validaCamposGrabar();
      if(validando){
      var edita = _this.filtro.IdConfig > 0 ? true : false;
      var usuario = JSON.parse(localStorage.getItem("Usuario"));

      var request = {
        "IdConfig": edita ? _this.filtro.IdConfig : 0,
        "Descripcion": _this.filtro.DescripcionConfig,
        "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
        "Tipo": _this.filtro.IdConfig > 0 ? '2' : '1'
      };

      request.DetalleEmpresa = [];
      _this.list.TablaDetalleEmpresa.forEach((_item) => {
            request.DetalleEmpresa.push({
              "EmpresaID": parseInt(_item.Id)
            });
      });

      request.DetalleNotificacion = [];
      _this.list.TablaDetalleNotificacion.forEach((_item) => {
            request.DetalleNotificacion.push({
              "NotificacionID": parseInt(_item.Id),
              "AlertaDesde": parseInt(_item.CantidadAlerta),
            });
      });

      request.DetalleResponsable = [];
      _this.list.TablaDetalleResponsable.forEach((_item) => {
            request.DetalleResponsable.push({
              "TipoDoc": String(_item.TipoDoc),
              "NumDoc": String(_item.NumDoc),
              "ApellidoNombre":String(_item.ApellidoNombre),
              "Email": String(_item.Email),
              "Ceular": String(_item.Ceular),
              "Cargo": String(_item.Cargo)
            });
      });


      await this.axios.post("api/configuracion/InsertUpdateConfiguraciones", request)
      .then(async (res) => {
      if (res.data.EsCorrecto) {
      
           if(res.data.Estado){

                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                _this.cleanGrabado();

            } else{
                APP.msg.warning(this.$toast, res.data.Mensaje);
            }
      } else {
          APP.msg.error(this.$toast, res.data.Mensaje);
      }
      })
      .catch((error) => {
          APP.msg.error( this.$toast,  "Token expirado Inicie session..!!" + error);
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
      });
     }
    },
    validaCamposGrabar: async function(){
      let _this = this;
      var response = true;

      if(_this.filtro.DescripcionConfig === ''){
        _this.nextFocus(_this.$refs.refDescripcionConfig, 'input', 300);
        APP.msg.warning(this.$toast, "Ingrese Descripción");
        response = false;
        return response;
      }
      if(_this.list.TablaDetalleNotificacion.length == 0){
        APP.msg.warning(this.$toast, "Ingrese detalle Notificaciones");
        response = false;
        return response;
      }
      if(_this.list.TablaDetalleEmpresa.length == 0){
        APP.msg.warning(this.$toast, "Ingrese detalle empresas");
        response = false;
        return response;
      }
      if(_this.list.TablaDetalleResponsable.length == 0){
        APP.msg.warning(this.$toast, "Ingrese detalle responsable");
        response = false;
        return response;
      }
      return response;
    },
    cleanGrabado: function(){
      let _this = this;

    _this.list.TablaDetalleEmpresa = [];
    _this.list.TablaDetalleNotificacion = [];
    _this.list.TablaDetalleResponsable = [];
    
     _this.vselect.TipoDoc = null;
     _this.vselect.Empresa = null;
     _this.filtro.Documento = "";
     _this.filtro.ApellidoNombre = "";
     _this.filtro.Email = "";
     _this.filtro.Celular = "";
     _this.filtro.Cargo = "";
     _this.filtro.DescripcionConfig = "";
     _this.filtro.IdConfig = 0;
    },
    BuscarReniec: async function () {
      let _this = this;

    if(_this.vselect.TipoDoc != null && _this.vselect.TipoDoc.Id === 1){
      if(_this.filtro.Documento != "" && _this.filtro.Documento.length == 8){
          this.axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          await this.axios.get("api/netcore/BusquedaPersona_Reniec/" + _this.filtro.Documento)
          .then((res) => {
              if (res.data.EsCorrecto) {

              var _data = _.cloneDeep(res.data.Valor);
                _this.filtro.ApellidoNombre = _data.ApellidoPaterno + " " + _data.ApellidoMaterno + " " +  _data.Nombres;
                _this.nextFocus(_this.$refs.refEmail, 'input', 300);

              } else { APP.msg.error(this.$toast, res.data.Mensaje);}
            })
            .catch((error) => {
              APP.msg.error( this.$toast, "Token expirado Inicie session..!!" + error);
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
        }else{
          APP.msg.warning(this.$toast, "Ingrese Documento");
          _this.nextFocus(_this.$refs.refDocumento, 'input', 300);
        }
      }else{
        APP.msg.warning( this.$toast, "Seleccione Tipo Documento...!!");
        _this.nextFocus(_this.$refs.refTipoDoc, 'combo', 300);
      }

    },
  },
  computed: {
    checkAll: {
            get: function () {
                if (this.list.Popup.TablaDetalle.length > 0) {
                    for (var i = 0; i < this.list.Popup.TablaDetalle.length; i++) {
                        if (!this.list.Popup.TablaDetalle[i].Selected) return false;
                    }
                    return true;
                } else return false;
            },
            set: function (value) {
                this.list.Popup.TablaDetalle.forEach(function (item) {
                    item.Selected = value;
                })
            }
        },
  },
  watch: {
    'list.Popup.TablaDetalle': {
       handler: function (newVal) {
          for (var i = 0; i < newVal.length; i++) {
              if(!newVal[i].Selected){
                  newVal[i].CantidadAlerta = 7;
                }
          }
      },
       deep: true
    },
  },
}
</script>

<style scoped>

</style>