import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import ClaseBrevete from "../views/ClaseBrevete/ClaseBrevete.vue";
import RevisionTecnica from "../views/RevisionTecnica/RevisionTecnica.vue";
import Tucs from "../views/Tucs/Tucs.vue";
import SoatSeguroVehicular from '../views/SoatSegurioVeh/SoatSegVeh.vue';
import LicenciaConducir from '../views/Licencia/LicenciaConducir.vue';
import Indicadores from '../views/Indicadores/Indicadores.vue';
import ImportacionMasivo from '../views/Masivo/ImportMasivo.vue';
import appVueNotificacionAlerta from '../views/NotificacionAlerta/NotificacionAlerta.vue';
import ControlFlota from '../views/ControlFlota/ControlFlota.vue';
import appVueApoyo from '../views/OperApoyo/Apoyo.vue';
import appVueGps from '../views/OperGps/Gps.vue';
import appVueSupervisor from '../views/OperSupervisor/Supervisor.vue';

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/claseBrevete",
    name: "ClaseBrevete",
    component: ClaseBrevete
  },
  {
    path: "/revisionTecnica",
    name: "RevisionTecnica",
    component: RevisionTecnica
  },
  {
    path: "/tucs",
    name: "Tucs",
    component: Tucs
  },
  {
    path: "/soatSeguroVehicular",
    name: "SoatSeguroVehicular",
    component: SoatSeguroVehicular
  },
  {
    path: "/licenciaConducir",
    name: "LicenciaConducir",
    component: LicenciaConducir
  },
  {
    path: "/indicadores",
    name: "Indicadores",
    component: Indicadores
  },
  {
    path: "/inporteMasivo",
    name: "ImportacionMasivo",
    component: ImportacionMasivo
  },
  {
    path: "/notificacionAlertas",
    name: "NotificacionAlertas",
    component: appVueNotificacionAlerta
  },
  {
    path: "/controlFlota",
    name: "ControlFlota",
    component: ControlFlota
  },
  {
    path: "/apoyo",
    name: "Apoyo",
    component: appVueApoyo
  },
  {
    path: "/gps",
    name: "Gps",
    component: appVueGps
  },
  {
    path: "/supervisor",
    name: "Supervisor",
    component: appVueSupervisor
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isLogged = JSON.parse(localStorage.getItem("SessionToken"));
  if (to.name !== 'Login' && !isLogged && !store.state.isLogeado) {
    localStorage.clear();
    next({ name: 'Login' });
  }else {
    next()
  }
    
})


export default router;