<template>
  <div class="p-0" v-show="vshowRevTecnica">
 
    <div class="Container">
    <div style="margin-bottom: 5px !important">
      <button @click="geBusqueda()" class="btn btn-secondary" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-refresh"></i></span
        >Buscar
      </button>
      <button class="btn btn-warning" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Descargar Plantilla
      </button>
      <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-arrow-right"></i></span>Importar Masivo Xml
      </button>
      <!-- <button  @click="openFileManager2" class="btn btn-success" style="margin-right: 3px !important">
        <span style="margin-right: 5px"><i class="fa fa-file-excel-o"></i></span
        >Importar Masivo Xml
        <input @change="readExcel($event)" id="file-upload-2" type="file" class="hidden" accept=".xlsx, .xls, .xlsm" style="visibility: hidden; display: none;"/>
      </button> -->
    </div>
    <div class="White-Box">
      <div class="form-div" style="margin-bottom: 10px">
        <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
          <label class="control-label label-sm">EMPRESA</label>
          <div class="">
            <v-select
              v-model="vselect.Empresa"
              name="Empresa"
              :clearable="false"
              class="v-select-form"
              label="Descripcion"
              placeholder="--Seleccione empresa--"
              :options="list.Empresa"
              ref="refEmpresa"
            >
            </v-select>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12 separate-in-form">
          <label class="control-label label-sm">PLACA</label>
          <div class="">
            <input
              v-model="filtro.Placa"
              name="Placa"
              class="form-control input-sm"
              placeholder=""
              ref="refPlaca"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
          <label class="control-label label-sm">ESTADO</label>
          <div class="">
            <v-select
              v-model="vselect.Estado"
              name="Estado"
              :clearable="true"
              class="v-select-form"
              label="label"
              placeholder="--Seleccione estado--"
              :options="list.Estado"
              ref="refEstado"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="  col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0 m-b-10 paginate-padding" style="overflow-x: auto">
          <table class=" table table-responsive table-hover table-bordered custom-table">
            <thead>
              <tr>
                <th>EMPRESA</th>
                <th>PLACA</th>
                <th>TIPO</th>
                <th>FLOTA</th>
                <th>REVISION TECNICA</th>
                <th>CERTIFICADO</th>
                <th>INFORME</th>
                <th>PROXIMA REVISIÓN</th>
                <th>ESTADO</th>
                <th>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <!-- <paginate ref="paginator" name="items" :list="list.TablaDetalle" :per="per"> -->
              <tr v-for="(item, key) in list.TablaDetalle" :key="key + 1"
                v-show=" (pag - 1) * NUM_RESULTS <= key && pag * NUM_RESULTS > key " >
                <td class="text-left" style="width: 20%">{{ item.Empresa }}</td>
                <td class="text-left">{{ item.Placa }}</td>
                <td class="text-left">{{ item.TipoVehiculo }}</td>
                <td class="text-left">{{ item.FlotaVehiculo }}</td>
                <td class="text-center">{{ item.FecInspeccion }}</td>
                <td class="text-left">{{ item.NroCertificado }}</td>
                <td class="text-left">{{ item.NroInforme }}</td>
                <td class="text-center">{{ item.FecProximaInspeccion }}</td>
                <td style="width: 13%" class="text-center">
                  <template v-if="item.estado === 'VIGENTE'">
                    <div
                      class="label label-default"
                      style=" color: white;
                        background-color: #009900;
                        padding: 3px 10px;
                        border-radius: 5px;"
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'VENCIDO'">
                    <div
                      class="label label-default"
                      style="
                        color: white;
                        background-color: #e02e2e;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'POR VENCER'">
                    <div
                      class="label label-default"
                      style="color: white;
                        background-color: rgb(246 137 54);
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                  <template v-else-if="item.estado === 'NO REGISTRADO'">
                    <div
                      class="label label-default"
                      style="
                        color: white;
                        background-color: #8d8d8d;
                        padding: 3px 10px;
                        border-radius: 5px;
                      "
                    >
                      {{ item.estado }}
                    </div>
                  </template>
                </td>
                <td class="text-center" style="text-align: center">
                  <template v-if="item.RevisionTecnicaId > 0">
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-primary m-r-10"
                      id="Info"
                      style="color: #178719; margin-right: 5px !important"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span
                        class="fa fa-pencil"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-danger m-r-10"
                      id="Info"
                      style="color: #178719"
                      @click="Delete(item)"
                    >
                      <span
                        class="fa fa-trash-o"
                        style="font-size: 1.6em"
                      ></span>
                    </a>
                  </template>
                  <template v-else>
                    <a
                      href="javascript:void(0)"
                      title="Ver Documento"
                      class="text-success m-r-10"
                      id="Info"
                      style="color: #178719"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click="openModal(item)"
                    >
                      <span class="fa fa-gear" style="font-size: 1.6em"></span>
                    </a>
                  </template>
                </td>
              </tr>
              <!-- </paginate> -->
            </tbody>
          </table>
        </div>
        <div
          class="col-md-12 col-sm-12 col-xs-12 p-0"
          style="margin-top: 10px !important"
        >
          <nav aria-label="Page navigation" class="text-center">
            <ul class="pagination text-center pull-right">
              <li>
                <a
                  href="#"
                  aria-label="Previous"
                  v-show="pag != 1"
                  @click.prevent="pag -= 1"
                  style="padding: 0px 0px 0px 0px"
                >
                  <span aria-hidden="true">
                    <button
                      class="btn btn-info"
                      style="height: 25px; line-height: 10px; color: #344767"
                    >
                      <i class="fa fa-angle-double-left"></i>
                    </button>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  aria-label="Next"
                  v-show="(pag * NUM_RESULTS) / list.TablaDetalle.length < 1"
                  @click.prevent="pag += 1"
                  style="padding: 0px 10px 0px 5px"
                >
                  <span aria-hidden="true">
                    <button
                      class="btn btn-info"
                      style="height: 25px; line-height: 10px; color: #344767"
                    >
                      <i class="fa fa-angle-double-right"></i>
                    </button>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    </div>

  <!-- Modal -->
    <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Nuevo Revisión Tecnica
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Placa</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Placa"
                  name="Estado"
                  class="form-control input-sm"
                  placeholder=""
                  @input="filtro.Popup.Placa = $event.target.value.toUpperCase()"
                  @keyup.enter="$refs.refTipo.focus()"
                  ref="refPlaca"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Tipo</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Tipo"
                  name="Estado"
                  class="form-control input-sm"
                  placeholder=""
                  @input="filtro.Popup.Tipo = $event.target.value.toUpperCase()"
                  @keyup.enter="$refs.refFlota.focus()"
                  ref="refTipo"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Flota</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Flota"
                  name="Estado"
                  class="form-control input-sm"
                  placeholder=""
                  @input="filtro.Popup.Flota = $event.target.value.toUpperCase()"
                  @keyup.enter="$refs.refRevisionTecnica.focus()"
                  ref="refFlota"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Revision Tecnica</label>
              <div class="">
                <input
                  v-model="filtro.Popup.RevisionTecnica"
                  name="Estado"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @input=" filtro.Popup.RevisionTecnica = $event.target.value.toUpperCase()"
                  @keyup.enter="$refs.refPlanta.$refs.search.focus()"
                  ref="refRevisionTecnica"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Planta</label>
              <div class="">
                <v-select
                  v-model="vselect.Popup.Planta"
                  name="TipoDoc"
                  :clearable="false"
                  class="v-select-form"
                  label="Descripcion"
                  placeholder="--Seleccione--"
                  :options="list.Popup.Planta"
                  :filterable="false"
                  @search="getPlanta"
                  @keyup.enter="nextFocus($refs.refCertificado, 'input', 300)"
                  ref="refPlanta"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Certificado</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Certificado"
                  name="Certificado"
                  class="form-control input-sm"
                  placeholder=""
                  @input="filtro.Popup.Certificado = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refInforme, 'input', 100)"
                  ref="refCertificado"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Informe</label>
              <div class="">
                <input
                  v-model="filtro.Popup.Informe"
                  name="Informe"
                  class="form-control input-sm"
                  placeholder=""
                  @input=" filtro.Popup.Informe = $event.target.value.toUpperCase()"
                  @keyup.enter="nextFocus($refs.refProximaRevision, 'input', 100)"
                  ref="refInforme"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Proxima Revisión</label>
              <div class="">
                <input
                  v-model="filtro.Popup.ProximaRevision"
                  name="ProximaRevision"
                  type="date"
                  class="form-control input-sm"
                  placeholder=""
                  @keyup.enter="nextFocus($refs.btnGrabar, 'btn', 100)"
                  ref="refProximaRevision"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Días Faltante</label>
              <div class="">
                <input
                  v-model="filtro.Popup.DiaFaltante"
                  name="DiaFaltante"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refDiaFaltante"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Estado</label>
              <div class="">
                <template v-if="filtro.Popup.Estado === 'VIGENTE'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #009900;
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'VENCIDO'">
                  <div
                    class="label label-default"
                    style="text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: #e02e2e;
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
                <template v-else-if="filtro.Popup.Estado === 'POR VENCER'">
                  <div
                    class="label label-default"
                    style="
                      text-align: center;
                      margin-top: 3px;
                      color: white;
                      background-color: rgb(246 137 54);
                      padding: 3px 10px;
                      border-radius: 5px;
                    "
                  >
                    {{ filtro.Popup.Estado }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="form-div col-md-12 col-sm-12 col-xs-12 p-0">
            <div class="col-md-12 col-sm-12 col-xs-12 separate-in-form">
              <label class="control-label label-sm">Observación</label>
              <div class="">
                <textarea
                  v-model="filtro.Popup.Observacion"
                  name="Observacion"
                  class="form-control input-sm"
                  placeholder=""
                  ref="refObservacion"
                  autocomplete="off"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cleanField()" type="button" class="btn btn-danger" data-bs-dismiss="modal" id="closeModal">
            <i class="fa fa-close" style="margin-right: 5px"></i>Close
          </button>
          <button @click="register()" type="button" class="btn btn-primary" ref="btnGrabar">
            <i class="fa fa-save" style="margin-right: 5px"></i>Grabar
          </button>
        </div>
      </div>
    </div>
    </div>
 </div>

 <div class="p-0" v-show="!vshowRevTecnica">
  <div class="Container">
    <div style="margin-bottom: 5px !important">
     <button  @click="VerImporteMasivo" class="btn btn-success" style="margin-right: 3px !important">
               <span style="margin-right: 5px"><i class="fa fa-arrow-left"></i></span>Regresar
     </button>
    </div>
    <div class="White-Box">
           <appVueAddRevisionTecnica>
           </appVueAddRevisionTecnica>
     </div>
  </div>
</div>
</template>

<script>
import { APP } from "../../Content/base";
// import * as XLSX from 'xlsx'
import appVueAddRevisionTecnica from "../RevisionTecnica/AddRevisionTecnica.vue";
//import _ from 'lodash';

export default {
  name: "RevisionTecnica",
  el: "#RevisionTecnica",
  data() {
    return {
      filtro: {
        Placa: "",
        Popup: {
          RevisionTecnicaId: 0,
          EmpresaId: 0,
          VehiculoId: "",
          Tipo: "",
          Placa: "",
          Flota: "",
          RevisionTecnica: "",
          Certificado: "",
          Informe: "",
          Estado: "",
          ProximaRevision: "",
          DiaFaltante: "",
          Observacion: "",
          PlantaRevisionId:0,
        },
      },
      list: {
        Empresa: [],
        Estado: [
          { id: 0, label: "NO REGISTRADO" },
          { id: 1, label: "VIGENTE" },
          { id: 2, label: "VENCIDO" },
          { id: 3, label: "POR VENCER" },
        ],
        Popup: {
          Planta: [{ id: 1, label: "PLANTA 1" }],
        },

        TablaDetalle: [],
      },
      vselect: {
        Empresa: null,
        Estado: null,
        Popup: {
          Planta: null,
        },
      },
      vshowRevTecnica:true,
      paginate: ["items"],
      per: 10,

      NUM_RESULTS: 10, // Numero de resultados por página
      pag: 1, // Página inicial
    };
  },
  async mounted() {
    let _this = this;
    //_this.list.Empresa = this.$store.state.ListaEmpresa;
    await _this.getEmpresa();
  },
  components: {appVueAddRevisionTecnica},
  methods: {
    nextFocus: async function (ref, type, time = 100) {
      setTimeout(() => {
        switch (type) {
          case "input":
            ref.focus();
            break;
          case "money":
            ref.$refs.money.focus();
            break;
          case "radio":
            ref.$refs.input.focus();
            break;
          case "check":
            ref.$refs.input.focus();
            break;
          case "combo":
            ref.$refs.search.focus();
            break;
          case "btn":
            ref.focus();
            break;
          default:
            ref();
        }
      }, time);
    },
    getEmpresa: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/netcore/ListEmpresa")
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Empresa = res.data.Valor;
            _this.vselect.Empresa = _this.list.Empresa[0];
            //APP.msg.info(this.$toast, "Se encontro correctamente ..!");
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    getPlanta: async function (search) {
      let _this = this;

      if (!(await APP.Func.delayWhileIsSearching())) return;

      search = search ? search : "";

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get(
          "api/netcore/AutocompleteRevTecPlanta" + (search ? "/" + search : "")
        )
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.Popup.Planta = res.data.Valor;
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    geBusqueda: async function () {
      let _this = this;

      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      var request = {
        IdEmpresa: _this.vselect.Empresa ? parseInt(_this.vselect.Empresa.Id) : 1,
        Placa: _this.filtro.Placa || "",
        estado: _this.vselect.Estado ? parseInt(_this.vselect.Estado.id) : -1,
      };

      await this.axios
        .post("api/revisionTecnica/ListRevisionTecnicaPlanta", request)
        .then((res) => {
          if (res.data.EsCorrecto) {
            _this.list.TablaDetalle = res.data.Valor;
            _this.pag = 1;
            //APP.msg.info(this.$toast, "Se encontro correctamente ..!");
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    getFormula: async function (_Fecha) {
      let _this = this;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

      await this.axios
        .get("api/revisionTecnica/getFormula/" + _Fecha)
        .then((res) => {
          if (res.data.EsCorrecto) {
            var _Data = res.data.Valor;
            if (_Data) {
              _this.filtro.Popup.DiaFaltante = _Data.diasFaltantes;
              _this.filtro.Popup.Estado = _Data.estado;
            }
          } else {
            APP.msg.error(this.$toast, res.data.Mensaje);
          }
        })
        .catch((error) => {
          APP.msg.error(
            this.$toast,
            "Token expirado Inicie session..!!" + error
          );
          localStorage.clear();
          this.$store.state.isLogeado = false;
          this.$router.push("/");
        });
    },
    openModal: async function (_item) {
      let _this = this;
      setTimeout(async () => {
        _this.cleanField();
        _this.filtro.Popup.RevisionTecnicaId = _item.RevisionTecnicaId;

        if(_item.RevisionTecnicaId > 0){
          await _this.getPlanta(_item.PlantaRevisionId);
          _this.filtro.Popup.PlantaRevisionId = _item.PlantaRevisionId;

          if(_item.FecInspeccion){
            var sp1 =   _item.FecInspeccion.split('/');
            var newValue1 = sp1[2] + '-' + sp1[1]+ '-' + sp1[0];
            _this.filtro.Popup.RevisionTecnica = newValue1; 
          }

          if(_item.FecProximaInspeccion){
            var sp =   _item.FecProximaInspeccion.split('/');
            var newValue = sp[2] + '-' + sp[1]+ '-' + sp[0];
            _this.filtro.Popup.ProximaRevision = newValue; 
          }
          _this.filtro.Popup.Certificado = _item.NroCertificado;
          _this.filtro.Popup.Informe = _item.NroInforme;
          _this.filtro.Popup.Estado = _item.estado;
          _this.filtro.Popup.Observacion = _item.Observacion;
          _this.vselect.Popup.Planta =_this.list.Popup.Planta[0];
        }
        _this.filtro.Popup.EmpresaId = _item.EmpresaId;
        _this.filtro.Popup.VehiculoId = _item.VehiculoId;
        _this.filtro.Popup.Tipo = _item.TipoVehiculo;
        _this.filtro.Popup.Placa = _item.Placa;
        _this.filtro.Popup.Flota = _item.FlotaVehiculo;

        this.nextFocus(this.$refs.refRevisionTecnica, 'input', 200);
       
      }, 300);
      // _this.vselect.Popup.Planta = _this.list.Popup.Planta.find((o)=>{ return parseInt(o.Id) === parseInt(_item.PlantaRevisionId)});
      //_this.vselect.Popup.Planta = _.find(_this.list.Popup.Planta, (o)=>{ return parseInt(o.Id) === parseInt(_item.PlantaRevisionId) });
    },
    cleanField: function(){
        let _this = this;

        _this.filtro.Popup.RevisionTecnicaId = 0;
        _this.filtro.Popup.EmpresaId = 0;
        _this.filtro.Popup.VehiculoId = '';
        _this.filtro.Popup.Tipo = '';
        _this.filtro.Popup.Placa ='';
        _this.filtro.Popup.Flota = '';
        _this.filtro.Popup.RevisionTecnica = '';
        _this.filtro.Popup.Certificado = '';
        _this.filtro.Popup.Informe = '';
        _this.filtro.Popup.Estado = '';
        _this.filtro.Popup.ProximaRevision = '';
        _this.filtro.Popup.Observacion = '';
        _this.filtro.Popup.PlantaRevisionId = 0;
        _this.vselect.Popup.Planta =null;

    },
    register: async function () {
      let _this = this;
      var validar = await _this.validarCampos();
      if(validar){
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          var usuario = JSON.parse(localStorage.getItem("Usuario"));

          var request = {
            "RevisionTecnicaId": _this.filtro.Popup.RevisionTecnicaId > 0 ? parseInt( _this.filtro.Popup.RevisionTecnicaId) : 0,
            "PlantaRevisionId": _this.vselect.Popup.Planta ? parseInt(_this.vselect.Popup.Planta.Id) : 0,
            "EmpresaId": _this.filtro.Popup.EmpresaId,
            "VehiculoId": _this.filtro.Popup.VehiculoId || '',
            "NroCertificado": _this.filtro.Popup.Certificado,
            "NroInforme": _this.filtro.Popup.Informe,
            "FecInspeccion": _this.filtro.Popup.RevisionTecnica,
            "FecProximaInspeccion": _this.filtro.Popup.ProximaRevision,
            "Observacion": _this.filtro.Popup.Observacion,
            "UsrRegistro": usuario ? parseInt(usuario.UsuarioId) : 0,
            "Tipo":  _this.filtro.Popup.RevisionTecnicaId > 0 ? '2' : '1',
          };

          await this.axios
            .post("api/revisionTecnica/register", request)
            .then(async (res) => {
              if (res.data.EsCorrecto) {
              
                APP.msg.success(this.$toast, "Se registro correctamente ..!");
                document.getElementById('closeModal').click();
                _this.cleanField();
                await _this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });
      }
    },
    Delete: async function (_item) {
    
        if(await APP.msg.confirm(this.$swal.fire, "Eliminar", "se eliminara ¿Desea continuar?")){

          this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("SessionToken"));

          await this.axios
            .get("api/revisionTecnica/delete/" + (_item.RevisionTecnicaId > 0 ? String(_item.RevisionTecnicaId) : ''))
            .then(async (res) => {
              if (res.data.EsCorrecto) {
                APP.msg.success(this.$toast, "Se elimino correctamente ..!");
                await this.geBusqueda();
              } else {
                APP.msg.error(this.$toast, res.data.Mensaje);
              }
            })
            .catch((error) => {
              APP.msg.error(
                this.$toast,
                "Token expirado Inicie session..!!" + error
              );
              localStorage.clear();
              this.$store.state.isLogeado = false;
              this.$router.push("/");
            });

      }

    },
    validarCampos: async function(){
                let _this = this;
                var response = true;

                if( _this.filtro.Popup.RevisionTecnica === ''){
                  APP.msg.warning( this.$toast,"Ingrese Revisión Tecnica");
                  _this.nextFocus(this.$refs.refRevisionTecnica, 'input', 200);
                  response = false;
                  return response;
                }

                if( _this.vselect.Popup.Planta == null && !(_this.vselect.Popup.Planta && _this.vselect.Popup.Planta.Id)){
                  APP.msg.warning( this.$toast,"Ingrese Planta");
                  _this.nextFocus(this.$refs.refPlanta, 'combo', 200);
                  response = false;
                  return response;
                }

                if( _this.filtro.Popup.Certificado === ''){
                  APP.msg.warning( this.$toast,"Ingrese Certificado");
                  _this.nextFocus(this.$refs.refCertificado, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.Informe === ''){
                  APP.msg.warning( this.$toast,"Ingrese Informe");
                  _this.nextFocus(this.$refs.refInforme, 'input', 200);
                  response = false;
                  return response;
                }
                if( _this.filtro.Popup.ProximaRevision === ''){
                  APP.msg.warning( this.$toast,"Ingrese Proxima Revisión");
                  _this.nextFocus(this.$refs.refProximaRevision, 'input', 200);
                  response = false;
                  return response;
                }
                return response;
    },
    VerImporteMasivo: async function(){
      document.getElementById('IdLimpiarData').click();
      this.vshowRevTecnica = !this.vshowRevTecnica;
      if(this.vshowRevTecnica){
        await this.geBusqueda();
      }
    }
  },
  filters: {
    planta: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    "vselect.Empresa": async function (value, oldValue) {
      let _this = this;
      if (value != null && value != oldValue) {
        await _this.geBusqueda();
      }
    },
    "vselect.Estado": async function () {
      let _this = this;
        await _this.geBusqueda();
    },
    "filtro.Placa": async function(){

      if (!(await APP.Func.delayWhileIsSearching())) return;

      await this.geBusqueda();
    },
    "filtro.Popup.ProximaRevision": async function (value) {
      let _this = this;
      if (value != null && value.length == 10) {
        await _this.getFormula(value);
      }
    },
  },
};
</script>

<style  scoped>
</style>